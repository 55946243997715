#header {
  color: $textInverseColor;
  background: $themeColor;
  height: $headerHeight;
  padding: 0;
  z-index: 10;
  .navbar {
    height: $headerHeight;
  }
  a {
    color: $textInverseColor;
  }
  .btn-icon {
    position: relative;
    top: calculateRem(-2px);
  }
  .navbar-brand {
    margin-left: calculateRem(-15px);
    margin-right: 0;
    width: $menuWidth;
    img {
      max-height: 1.15 * $headerHeight;
    }
  }
  .dropdown-menu {
    width: 100%;
    .dropdown-item {
      font-size: calculateRem(13px);
      padding: calculateRem(5px) calculateRem(20px);
      color: $textColor;
      text-decoration: none;
      &:active {
        background: $themeColor;
        color: $textInverseColor;
      }
    }
  }
  .search-group {
    position: relative;
    border: solid 1px $themeColor;
    border-radius: 5px;
    .search-icon {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      position: absolute;
      left: calculateRem(20px);
      top: calculateRem(9px);
      color: $iconColor;
      font-size: 1.1rem;
      z-index: 10;
      pointer-events: none;
    }
    .dropdown-menu {
      margin-top: calculateRem(-2px);
    }
    .fa-caret-right{
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      position: absolute;
      right: calculateRem(12px);
      top: calculateRem(12px);
      color: $iconColor;
      z-index: 10;
      pointer-events: none;
    }
  }
  .search {
    font-size: calculateRem(13px);
    padding-left: calculateRem(45px);
    border: 0 none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: calculateRem(34px);
    & + .input-group-append {
      margin-left: 0;
    }
  }
  .search-btn,
  .type-btn {
    background: $topBarElementsBg;
    color: $textColor;
  }
  .search-btn {
    @include btn-states($topBarElementsBg, $textColor, $dropdownHoverBg);
    width: calculateRem(180px);
    border: calculateRem(1px) $topBarElementsBg solid;
    padding-top: calculateRem(4px);
    padding-bottom: calculateRem(4px);
  }
  .type-btn {
    position: relative;
    border: 0 none;
    width: calculateRem(175px);
    text-align: left;
    font-size: calculateRem(13px);
    padding: calculateRem(5px) calculateRem(20px);
    &:before {
      content: " ";
      width: 0;
      height: calculateRem(24px);
      border-left: calculateRem(1px) $formControlBorder solid;
      position: absolute;
      left: 0;
      top: calculateRem(7px);
    }
    &:after {
      content: "\f0d7";
      opacity: 0.4;
      font-size: calculateRem(14px);
      position: absolute;
      right: calculateRem(15px);
      top: 50%;
      margin-top: calculateRem(-6px);
    }
  }
  .btn-icon {
    @include btn-icon-states($iconColor, $textInverseColor);
    margin-left: calculateRem(10px);
  }
  .fa-bell {
    position: relative;
    &:after {
      content: " ";
      display: none;
      width: calculateRem(12px);
      height: calculateRem(12px);
      border: calculateRem(2px) $headerBg solid;
      border-radius: calculateRem(10px);
      position: absolute;
      top: calculateRem(-5px);
      right: calculateRem(-5px);
    }
    &.alerts:after,
    &.completed:after {
      display: block;
    }
    &.alerts:after {
      background: $headerLoadingAlertBg;
    }
    &.completed:after {
      background: $headerCompletedAlertBg;
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    font-size: calculateRem(14px);
    min-width: calculateRem(300px);
    width: auto;
    p {
      padding: calculateRem(5px) calculateRem(20px);
      font-weight: 600;
    }
    .dropdown-item {
      .status {
        display: inline-block;
        width: calculateRem(11px);
        height: calculateRem(11px);
        border-radius: 100%;
        margin-right: calculateRem(3px);
        position: relative;
        top: calculateRem(1px);
        border: calculateRem(1px) $topBarElementsBg solid;
        &.Submitted,
        &.Running {
          background: $headerLoadingAlertBg;
        }
        &.Succeeded {
          background: $headerCompletedAlertBg;
        }
        &.Failed {
          background: $headerFailedAlertBg;
        }
      }
    }
  }
  .separator {
    position: relative;
    padding-left: calculateRem(10px);
    margin-left: calculateRem(10px);
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 50%;
      height: calculateRem(28px);
      width: 0;
      margin-top: calculateRem(-15px);
      border-left: calculateRem(1px) $textInverseColor solid;
    }
  }
  .account {
    padding-right: calculateRem(50px);
    position: relative;
    font-size: calculateRem(13px);
    &:after {
      color: $textInverseColor;
      margin-left: calculateRem(7px);
      top: calculateRem(7px);
      line-height: 0.1rem;
      font-size: calculateRem(17px);
    }
    .fa-user {
      position: absolute;
      right: 0;
      font-size: calculateRem(30px);
      overflow: hidden;
      border-radius: 100%;
      width: calculateRem(38px);
      top: 0;
      height: calculateRem(38px);
      text-align: center;
      background: rgb(197, 197, 197);
      &:before {
        position: relative;
        top: calculateRem(10px);
      }
    }
    & + .dropdown-menu {
      margin-top: calculateRem(10px);
      .groups-container {
        margin: 0;
        padding: calculateRem(5px) calculateRem(20px);
        font-size: 0.9em;
        border-bottom: thin solid $commonBorder;
        dd {
          margin: 0;
        }
        ul {
          padding-left: calculateRem(19px);
          list-style-type: circle;
          margin: 0;
        }
      }
    }
  }
}
