@function calculateRem($size) {
  //$remSize: calc($size / 16px);
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

$themeColor: #1e2351;
$greyColor: #bcbccb;
$neutralColor: #888;
$errorColor: #da2323;

$headerBg: #1d2251;
$commonBg: white;
$headerCompletedAlertBg: #00ad0d;
$headerLoadingAlertBg: $themeColor;
$headerFailedAlertBg: $errorColor;
$menuBg: #1e2351;
$buttonBg: #51b5a5;
$contentBg: #f0f0f7;
$topBarElementsBg: #ffffff;
$dropdownHoverBg: #ecedee;
$menuItemActiveBg: #00619b;
$dataTableBg: #ffffff;
$dataTableHeaderBg: #f5f6fa;
$loginBtnBg: $menuBg;
$loginBtnHoverBg: #5a55aa;
$defaultBtnBg: #ffffff;
$resizeBarBg: #b3b3b3;
$drawActiveBtnBg: #fefefe;
$drawBtnBg: #ebebeb;
$extentBackdropBg: rgba(255, 255, 255, 0.8);
$loaderDotsBg: #ffffff;
$mapButtonBg: $menuBg;

$commonBorder: $greyColor;
$menuItemActiveBorder: $buttonBg;
$formControlBorder: $greyColor;
$menuDividerBorder: #aaa;
$dataTableBorder: #e0e0e0;
$paginationBorder: #d8dbe2;
$loginInputBorder: #e9e9f0;
$btnDefaultBorder: #a3a4ab;
$tabContentBorder: #e9e9f0;
$separatorBorder: #e0e0e0;
$searchBorder: #bcd1f1;
$layerSwitcherBorder: $greyColor;

$titleColor: #a3a6b4;
$textColor: #444;
$textInverseColor: #fff;
$breadcrumbActiveItemColor: #a6a7ad;
$iconColor: $greyColor;
$projectIconColor: $neutralColor;
$menuIconColor: #a5a4bf;
$dataTableHeaderColor: $neutralColor;
$dataTableTextColor: #585858;
$dataTableIconColor: #a6a6a6;
$dataTableIconHoverColor: #444;
$disabledPaginationColor: #dfdfe4;
$loginTitleColor: $menuBg;
$loginIntroColor: #585858;
$btnDefaultColor: #666;
$tabItemColor: #666;
$neutralLinkColor: $neutralColor;
$resizeBarColor: #000;

$defaultShadow: 0 0 calculateRem(3px) rgba(100, 100, 100, 0.1);
$commonShadow: rgba(0, 0, 0, 0.4);

$headerHeight: 70px;
$menuWidth: calculateRem(260px);
$menuCollapsedWidth: calculateRem(65px);
$filterHeight: calculateRem(236px);
