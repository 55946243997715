#account {
  .subsection {
    &:not(:last-child) {
      margin-bottom: calculateRem(20px);
    }
    h2 {
      font-size: calculateRem(25px);
      margin: 0 0 calculateRem(10px);
    }
  }
  .def-line {
    dt,
    dd {
      display: inline-block;
    }
  }
  .group-list {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    li {
      line-height: 1.1em;
      strong {
        font-size: 1.1em;
        line-height: 1.2em;
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
