@mixin btn-states(
  $baseColor,
  $baseTextColor,
  $hoverColor,
  $textHoverColor: $baseTextColor,
  $baseBorderColor: $baseColor,
  $borderHoverColor: $hoverColor,
  $activeColor: $hoverColor,
  $textActiveColor: $textHoverColor,
  $borderActiveColor: $activeColor
) {
  transition: color .25s ease, background-color .25s ease, border-color .25s ease;
  background-color: $baseColor;
  border-color: $baseBorderColor;
  color: $baseTextColor;
  outline: 0;
  &:disabled {
    background-color: $baseColor;
    border-color: $baseBorderColor;
    color: $baseTextColor;
  }
  &:not(:disabled):focus,
  &:not(:disabled):hover {
    background-color: $hoverColor;
    border-color: $borderHoverColor;
    color: $textHoverColor;
  }
  &:not(:disabled):active,
  &:not(:disabled):active:focus,
  &:not(:disabled):active:hover {
    background-color: $activeColor;
    border-color: $borderActiveColor;
    color: $textActiveColor;
    box-shadow: none;
  }
}

@mixin btn-icon-states($baseColor, $hoverColor, $activeColor: $hoverColor) {
  transition: color .25s ease;
  color: $baseColor;
  &:disabled {
    color: $baseColor;
  }
  &:not(:disabled):focus,
  &:not(:disabled):hover {
    color: $hoverColor
  }
  &:not(:disabled):active,
  &:not(:disabled):active:focus,
  &:not(:disabled):active:hover {
    color: $activeColor;
  }
}
