#footer {
  background: $themeColor;

  div {
    color: $textInverseColor;
    display: flex;
    justify-content: center;
  }

  .navbar {
    justify-content: space-evenly;
  }

  a {
    color: $textInverseColor;
  }
}