.ol-dragbox {
  border-color: $themeColor;
  background-color: rgba(255, 255, 255, 0.2);
  border-width: calculateRem(3px);
}

.ol-control {
  button {
    cursor: pointer;
    width: calculateRem(28px);
    height: calculateRem(28px);
    background-color: $mapButtonBg;
    transition: background-color 0.25s ease;
    &:hover,
    &:focus,
    &:active {
      background-color: $menuBg;
    }
  }
}

.ol-scale-line,
.ol-mouse-position {
  background: $mapButtonBg;
  opacity: 0.9;
}

.ol-scale-line {
  padding: calculateRem(6px) 0;
  width: calculateRem(165px);
  .ol-scale-line-inner {
    margin: 0 auto;
  }
}

.ol-mouse-position {
  top: auto;
  right: auto;
  left: calculateRem(180px);
  bottom: calculateRem(8px);
  font-size: calculateRem(12px);
  border-radius: calculateRem(4px);
  padding: calculateRem(5px) calculateRem(5px);
  color: $textInverseColor;
  width: calculateRem(190px);
  text-align: center;
}

.ol-overviewmap {
  left: calculateRem(8px);
  bottom: calculateRem(44px);
}

.ol-attribution {
  right: calculateRem(8px);
  bottom: calculateRem(48px);
}

.ol-rotate {
  top: calculateRem(68px);
  right: calculateRem(52px);
}

.ol-zoom-to-bbox-control {
  left: calculateRem(8px);
  right: auto;
  top: calculateRem(80px);
}

.ol-full-screen {
  top: auto;
  right: calculateRem(8px);
  bottom: calculateRem(8px);
  
}

.ol-layer-switcher-control {
  top: calculateRem(68px);
  right: calculateRem(8px);
}

.ol-layer-legend-control {
  top: calculateRem(112px);
  right: calculateRem(8px);
}

.ol-layer-measure-control {
  top: calculateRem(156px);
  right: calculateRem(8px);
}

.ol-layer-export-control {
  top: calculateRem(200px);
  right: calculateRem(8px);
}

.reset-points-control {
  top: calculateRem(244px);
  right: calculateRem(8px);
  z-index: 1;
}

.map-control-selector {
  z-index: 1;
  padding: 0;
  > div {
    opacity: 0.9;
    background: $mapButtonBg;
    padding: calculateRem(3px);
    padding-left: calculateRem(5px);
    border-radius: calculateRem(3px);
  }
  label {
    color: $textInverseColor;
    margin: 0;
    white-space: nowrap;
  }
  .form-control {
    height: auto;
    padding: 0 calculateRem(10px);
    border: 0 none;
    border-radius: calculateRem(3px);
  }
}

.destination-selector {
  bottom: calculateRem(8px);
  left: calculateRem(598px);
}

.bottom-controls {
  position: absolute;
  bottom: calculateRem(8px);
  left: calculateRem(378px);
  .ol-control {
    position: relative;
    top: 0;
    left: 0;
    & + .ol-control {
      margin-left: calculateRem(8px);
    }
  }
}

.map-modal {
  .modal-dialog {
    margin: 0;
    top: 2.5%;
    left: 2.5%;
    max-width: 95%;
    height: 95%;
    .modal-content {
      width: 100%;
      height: 100%;
      ng-component {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        height: 100%;
        overflow: auto;
        padding: calculateRem(5px) calculateRem(5px) 0;
      }
    }
  }
}

#map-modal {
  &.no-modal-bottom {
    padding-bottom: calculateRem(5px);
  }
  .close {
    position: absolute;
    outline: 0;
    z-index: 5;
    right: 0;
    top: 0;
    font-size: calculateRem(64px);
    background: white;
    padding: 0 calculateRem(15px);
    border-bottom-left-radius: calculateRem(4px);
  }
}

.map-container {
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.bbox-help {
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: calculateRem(20px);
  left: 0;
  width: 100%;
  z-index: 1;
  p {
    margin: 0 auto;
    background: $commonBg;
    display: inline-block;
    padding: calculateRem(5px) calculateRem(10px);
    border-radius: calculateRem(4px);
    box-shadow: 0 0 calculateRem(5px) $commonShadow;
  }
}

.destination-type {
  position: absolute;
  text-align: left;
  top: 8px;
  left: 48px;
  width: 20%;
  z-index: 1;

  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    font-size: calculateRem(16px);
    background-color: rgb(245,245,245);
    border: solid 1px grey;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0px;
    transition: 0.25s all ease;
  }
  input[type="radio"]:checked + label {
    background-color: rgb(245,245,245);
    font-weight: bold;
  }
}

.autocomplete {
  top: 40px;
  left: 60px;
  max-width: 300px;
  width: 45%;
  z-index: 1;
  padding: 3px;
  > div {
    background: $mapButtonBg;
    opacity: 0.9;
    padding: 3px;
    border-radius: 4px;
  }
  .btn {
    background: transparent;
    position: absolute;
    top: 6px;
    margin: 0;
    color: $textColor;
    opacity: 0.7;
    transition: opacity 0.25s ease;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      opacity: 1;
    }
  }
  .search-btn {
    left: 6px;
  }
  .reset-btn {
    right: 6px;
  }
  .form-control {
    border: 0 none;
    border-radius: 4px;
    height: auto;
    padding: 2px 30px;
  }
  .search-list {
    position: absolute;
    width: calc(100%);
    color: $textInverseColor;
    left: 0;
    top: 100%;
    padding: 0 3px 3px;
    margin-top: -3px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    ul {
      background: $menuBg;
      max-height: 300px;
      overflow: auto;
      margin: 0;
      padding: 0;
      list-style-type: none;
      border-radius: 4px;
    }
    li {
      a,
      a:not([href]):not([tabindex]) {
        display: block;
        padding: 1px 5px;
        font-size: 14px;
        color: $textInverseColor;
        &:hover,
        &:active,
        &:focus,
        &:active:hover,
        &:active:focus {
          background: $menuItemActiveBg;
          color: $textInverseColor;
        }
      }
    }
  }
  &:hover {
    .search-list {
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.layer-switcher {
  position: absolute;
  top: calculateRem(68px);
  right: 0;
  z-index: 10;
  overflow: auto;
  background: white;
  border: calculateRem(1px) $layerSwitcherBorder solid;
  box-shadow: calculateRem(-2px) calculateRem(2px) calculateRem(4px) rgba(0, 0, 0, 0.4);
  width: 50%;
  max-width: calculateRem(700px);
  transition: right 0.5s ease;
  .btn-icon {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
  .layer-switcher-header {
    padding: 0 calculateRem(10px);
    border-bottom: calculateRem(2px) $layerSwitcherBorder solid;
    h4 {
      margin: 0;
    }
    .form-control {
      height: auto;
      width: auto;
      padding: calculateRem(1px) calculateRem(10px);
      max-width: calculateRem(200px);
    }
    label {
      margin: 0;
    }
  }
  .layerswitcher-section-header {
    margin: 0;
    padding: calculateRem(5px) calculateRem(15px) calculateRem(7px);
    border-bottom: calculateRem(2px) $layerSwitcherBorder solid;
    font-size: calculateRem(18px);
  }
  .layer-switcher-rows-container {
    max-height: calculateRem(300px);
    overflow: auto;
    + .layerswitcher-section-header {
      border-top: calculateRem(2px) $layerSwitcherBorder solid;
    }
  }
  .no-layers {
    padding: calculateRem(3px) calculateRem(10px);
  }
  .trosace {
    position: relative;
    height: calculateRem(76px);
    min-width: calculateRem(145px);
    background: url("/assets/images/rosace.png") no-repeat center center;
    span {
      position: absolute;
    }
    .tnorth,
    .tsouth {
      width: 100%;
      left: 0;
      text-align: center;
    }
    .tnorth {
      top: 0;
    }
    .tsouth {
      bottom: 0;
    }
    .teast,
    .twest {
      top: 50%;
      margin-top: calculateRem(-9px);
    }
    .teast {
      left: calculateRem(100px);
    }
    .twest {
      right: calculateRem(100px);
    }
  }
}

.layer-legends {
  top: calculateRem(112px);
  z-index: 9;
  .card {
    border: 0 none;
  }
  .card-header {
    padding: 0;
  }
}

.layer-measure {
  top: calculateRem(156px);
  max-width: calculateRem(250px);
  z-index: 8;
  .measure-buttons {
    .btn-container {
      background: $greyColor;
      padding: calculateRem(1px);
      border-radius: 0.25rem;
      display: inline-block;
      width: auto;
    }
    .btn {
      font-size: calculateRem(24px);
      width: calculateRem(45px);
      opacity: 1;
      transition: color 0.25s ease, background-color 0.25s ease;
      color: $neutralColor;
      background-color: $drawBtnBg;

      &:focus,
      &:hover,
      &:active,
      &:active:focus,
      &:active:hover {
        color: $themeColor;
      }
      &.active {
        background-color: $drawActiveBtnBg;
        color: $textColor;
      }
    }
    .btn-length {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 1px;
    }
    .btn-area {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .measure-result {
    strong {
      font-weight: 600;
    }
  }
}

.layer-export {
  top: calculateRem(200px);
  max-width: calculateRem(250px);
  z-index: 7;
}

// mandatory to keep style for dragula mirror
.layer-switcher-row {
  padding: 0 calculateRem(10px);
  border-bottom: calculateRem(1px) $layerSwitcherBorder solid;
  background: white;
  .btn-icon {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
    &.drag-handler {
      cursor: grab;
    }
  }
  &.gu-transit {
    background: $layerSwitcherBorder;
    opacity: 0.5;
    * {
      visibility: hidden;
    }
  }
  &.gu-mirror {
    .btn-icon {
      &.drag-handler {
        cursor: grabbing;
      }
    }
  }
  &:last-child {
    border-bottom: 0 none;
  }

  .btn-close {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.6rem;
  }
  .title-link,
  .title-result {
    position: relative;
    top: calculateRem(-1px);
    display: inline-block;
    padding: calculateRem(3px) 0;
  }
  .title-link {
    &:not([href]):not([tabindex]) {
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
  .form-check-label {
    padding-left: calculateRem(23px);
    &:before {
      width: calculateRem(20px);
      height: calculateRem(20px);
    }
    &:after {
      left: calculateRem(4px);
      top: calculateRem(5px);
    }
  }
  .slider-container {
    min-width: calculateRem(50px);
    position: relative;
    top: calculateRem(-3px);
  }
  .ng5-slider {
    margin: 0;
    .ng5-slider-bar-wrapper {
      height: auto;
      padding: 0;
      margin: 0;
    }
    .ng5-slider-pointer {
      width: calculateRem(14px);
      height: calculateRem(14px);
      top: calculateRem(-5px);
      outline: calculateRem(0px);
      background-color: $themeColor;
      transition: background-color 0.25s ease;
      &:hover {
        background-color: darken($themeColor, 10%);
      }
      &:after {
        display: none;
      }
    }
    .ng5-slider-selection {
      background-color: $themeColor;
    }
  }
  .layer-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out, border-top-width 0.25s ease-out;
    border-top: 0 $layerSwitcherBorder dotted;
    padding: 0 calculateRem(14px);
    margin: 0 calculateRem(-10px);
    font-size: 0.9em;
    &.open {
      max-height: calculateRem(500px);
      border-top-width: calculateRem(1px);
      transition: max-height 0.25s ease-in;
    }
    dt {
      padding-bottom: calculateRem(2px);
      margin-bottom: calculateRem(3px);
      border-bottom: calculateRem(1px) $themeColor solid;
      &:first-child {
        padding-top: calculateRem(5px);
      }
    }
    dd {
      &:last-child {
        padding-bottom: calculateRem(5px);
      }
      address {
        margin: 0;
        strong {
          font-weight: 600;
        }
      }
    }
  }
}

.result-popup {
  position: absolute;
  top: calculateRem(-105px);
  left: calculateRem(18px);
  background: $contentBg;
  padding: calculateRem(15px) 0 calculateRem(15px) calculateRem(15px);
  border-radius: calculateRem(6px);
  box-shadow: 0 0 5px $commonShadow;
  z-index: 1;
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
  }
  &:before {
    border: transparent solid calculateRem(10px);
    border-right-color: rgba($commonShadow, 0.4);
    margin-top: calculateRem(-10px);
    left: calculateRem(-20px);
  }
  &:after {
    border: transparent solid calculateRem(9px);
    border-right-color: $contentBg;
    margin-top: calculateRem(-9px);
    left: calculateRem(-18px);
  }

  dl {
    margin: 0;
    width: calculateRem(200px);
    height: calculateRem(180px);
    overflow: auto;
  }
}
