.admin-layout {
  background: $commonBg;
  box-shadow: $defaultShadow;
  .admin-nav {
    background: $menuBg;
    .navbar-brand,
    .nav-link {
      color: $textInverseColor;
    }
    .nav-link {
      padding: calculateRem(16px) calculateRem(16px) calculateRem(10px);
      border-bottom: calculateRem(6px) transparent solid;
      transition: background-color .3s ease, border-bottom .3s ease;
      color: $textInverseColor;
      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $textInverseColor;
        text-decoration: none;
        background-color: $menuItemActiveBg;
        border-bottom-color: $menuItemActiveBorder;
      }
    }
  }
  .status-dot {
    display: inline-block;
    width: calculateRem(10px);
    height: calculateRem(10px);
    background: $errorColor;
    border-radius: 50%;
    &.active {
      background: $headerCompletedAlertBg;
    }
  }
  .admin-page {
    > ng-component {
      height: 100%;
    }
    .page-header {
      padding: calculateRem(15px) calculateRem(20px) 0;
    }
    .page-content {
      padding: 0 calculateRem(20px) calculateRem(20px);
      .data-table {
        overflow: auto;
        max-height: 100%;
        .fa {
          margin-right: calculateRem(5px);
        }
        .delete-icon {
          font-size: calculateRem(18px);
          color: $errorColor;
          margin: auto;
          &:hover {
            color: darken($errorColor,10%);
          }
        }
        th.name {
          width: 500px;
        }
        td > div {
          height: 25px;
          .flag-link {
            font-size: 16px;
          }
          .flag-link:hover {
            text-decoration: none;
          }
          .flag-link:not(:last-child) {
            margin-right: 5px;
          }
        }
        td.name-cell a {
          display: inline-block;
          max-width: 450px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          em {
            color: unset;
          }
        }
      }
    }
  }
}