#project-detail {
  h1 {
    margin-bottom: calculateRem(10px);
    .fa {
      color: $projectIconColor;
      margin-right: calculateRem(10px);
      font-size: 0.9em;
    }
  }
  label[for="language"] {
    font-size: 1.15em;
  }
  .description {
    color: $dataTableHeaderColor;
    font-size: calculateRem(13px);
    margin: 0;
  }
  .page-content {
    overflow: unset;
  }
  .subcontent {
    padding: 0;
    overflow: unset;
    max-height: unset;
  }
  .tab-content {
    border-color: $tabContentBorder;
    padding: 0;
    position: relative;
    height: 100%;
    .tab-pane {
      height: 100%;
    }
    .add-btn {
      position: absolute;
      bottom: 100%;
      right: calculateRem(7px);
      padding: calculateRem(5px) calculateRem(25px);
      margin-bottom: calculateRem(7px);
    }
  }
  .nav-link {
    border: 0 none;
    border-bottom: calculateRem(2px) transparent solid;
    padding: calculateRem(12px) calculateRem(30px);
    color: $tabItemColor;
    margin-right: calculateRem(3px);
    &:focus,
    &:hover {
      border-color: $tabItemColor;
      text-decoration: none;
    }
    &:active,
    &.active,
    &:active:hover,
    &:active:focus,
    &.active:hover,
    &.active:focus {
      color: $themeColor;
      border-color: $themeColor;
      text-decoration: none;
    }
  }
  .data-table {
    margin: 0 -1rem;
    overflow: none;
    .actions {
      width: calculateRem(50px);
    }
    .url {
      width: calculateRem(350px);
      a {
        display: block;
        width: calculateRem(326px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .title-button-container {
    button {
      white-space: nowrap;
    }
  }
  .meta-detail {
    overflow: none;
  }
  .page-citation-choice {
    label {
      font-size: 1.15em;
    }
  }
  .copy-to-clipboard {
    display: inline-block;
    background-color: rgb(220,220,220);
    color: rgb(40,40,40);
    padding: .25em .4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: all .15s;
  }
  .copy-to-clipboard:hover {
    cursor: pointer;
    background-color: rgb(200,200,200);
    color: rgb(20,20,20);
  }
}
