#landpage {
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
  overflow: unset;

  button {
    font-size: 1.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
  }

  .page-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 200px;
    }
  }

  .page-content {
    height: auto;
    margin-top: 5rem;
    overflow: unset;

    img {
      max-height: 80px;
    }
  }
}