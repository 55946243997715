.meta-detail {
  h2 {
    font-size: calculateRem(24px);
    margin-bottom: calculateRem(20px);
    margin-top: 0;
  }
  label[for="language"] {
    font-size: 1.15em;
  }
  .accordion-title {
    padding-bottom: calculateRem(3px);
    margin-bottom: calculateRem(3px);
    border-bottom: calculateRem(2px) solid $themeColor;
  }
  .accordion-content {
    max-height: calculateRem(300px);
    overflow: auto;
    transition: max-height 0.3s linear;
    &.closed {
      max-height: 0;
    }
  }
  .accordion-btn {
    padding: 0 calculateRem(10px) 0 0;
    span {
      font-size: calculateRem(15px);
      font-weight: bold;
      color: $textColor;
    }
    .fa {
      transform: rotate(0deg);
      transition: transform 0.3s linear, opacity 0.3s ease;
      opacity: 0.6;
      &.rotated {
        transform: rotate(180deg);
      }
    }
    &:hover,
    &:focus,
    &:active {
      .fa {
        opacity: 1;
      }
    }
  }
  .contacts {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .contactIdentifiers {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      margin-bottom: 0px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .page-citation-choice {
    label {
      font-size: 1.15em;
    }
  }
  .copy-to-clipboard {
    display: inline-block;
    background-color: rgb(220,220,220);
    color: rgb(40,40,40);
    padding: .25em .4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: all .15s;
  }
  .copy-to-clipboard:hover {
    cursor: pointer;
    background-color: rgb(200,200,200);
    color: rgb(20,20,20);
  }
}