.progress-bar {
    visibility: collapse;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: all 0.3s ease;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
}

// style de la bar générale
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 3px;
  height: 3em;
  width: 100%;
}

.progress-block{
  padding: 2rem;
  color: $textInverseColor;
  background: $menuBg;
  border-radius: 5px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: $menuIconColor;
}

.p-progressbar .p-progressbar-label {
  line-height: 3rem;
}