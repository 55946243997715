// page avec un formulaire d'édition
.edit-form {
  background: $commonBg;
  padding: calculateRem(20px);
  overflow: none;
  .form-separator {
    margin: calculateRem(15px) 0 calculateRem(30px);
    border-color: $formControlBorder;
  }
  .form-title {
    font-size: calculateRem(24px);
  }
}

// gestion des champs
.edit-fields-form {
  /* .ng-invalid {
    border-color: $errorColor;
    .form-control {
      border-color: $errorColor;
    }
  } */
  &.ng-submitted {
    .ng-invalid {
      border-color: $errorColor;
      .form-control {
        border-color: $errorColor;
      }
    }
  }
  label[for="language"] {
    font-size: 1.15em;
  }
  .vertical-list {
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 0px;
    }
  }
  /* .ng-invalid {
    border-color: $formControlBorder;
  } */
  .column {
    padding: 0px;
  }
  .pop-up-container {
    position: absolute;
    z-index: 2;
    margin-top: 5px;
    font-size: calculateRem(13px);
    .arrow-up {
      position: relative;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $buttonBg;
      left: 10px;
    }
    .arrow-up:after {
      content: "";
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent $buttonBg;
      position: absolute;
      top: 1px;
      left: -10px;
    }
    .pop-up-content {
      border: solid 1px $buttonBg;
      border-radius: .25rem;
      background-color: white;
      max-width: 200px;
      box-shadow: rgba(0,0,0,.15) 1px 1px 5px;
      .pop-up-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 5px;
        font-size: calculateRem(14px);
        color: white;
        background-color: $buttonBg;
        .pop-up-close-button {
          font-size: calculateRem(20px);
          color: white;
          background-color: transparent;
          border: none;
          margin-right: 3px;
        }
        .pop-up-close-button:hover {
          font-weight: bold;
        }
      }
      .pop-up-body {
        padding: 5px;
      }
    }
  }
  .pop-up-container.hidden {
    display: none;
  }
  fieldset {
    position: relative;
    border: thin $formControlBorder solid;
    padding: calculateRem(15px);
    border-radius: calculateRem(3px);
    legend {
      position: absolute;
      font-size: calculateRem(16px);
      background: white;
      z-index: 1;
      width: auto;
      padding: 0 calculateRem(10px);
      top: calculateRem(-13px);
      left: calculateRem(10px);
    }
    label {
      margin-right: calculateRem(10px);
    }
    &.with-close-btn {
      .btn-close {
        top: calculateRem(-8px);
        right: calculateRem(-15px);
        background: $commonBg;
      }
    }
  }
  .form-group {
    label {
      small {
        display: block;
        color: $neutralColor;
      }
    }
    .alert {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      margin: 0;
      padding: calculateRem(5px) calculateRem(10px);
      font-size: calculateRem(14px);
      z-index: 1;
      &.not-absolute {
        position: unset;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    p-autocomplete {
      & > span.dropdown {
        display: flex;
        flex-direction: row;
        padding: 0;
        ul {
          padding-left: 0.375rem;
          padding-right: 0.375rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
        button {
          background-color: $themeColor;
          border-color: $themeColor;
          border-top-right-radius: .25rem;
          border-bottom-right-radius: .25rem;
          color: $textInverseColor;
          height: 2.5em !important;
        }
      }
      ul {
        width: 100%;
        li {
          /* width: 100%; */
          input {
            width: 100%;
          }
        }
      }
    }
  }
  .p-button-icon-only  {
    @include btn-states($themeColor, $textInverseColor, darken($themeColor, 10%), $textInverseColor);
    height: auto;
    border: none;
    border-radius: 0 calculateRem(4px) calculateRem(4px) 0;
    position: relative;
  }
  .file-size-error {
    color: $errorColor;
  }
  .p-fileupload-buttonbar {
    @include btn-states($themeColor, $textInverseColor, darken($themeColor, 10%));
    border-radius: calculateRem(4px);
  }
  .p-fileupload-content {
    padding: 0 0 0 calculateRem(8px);
  }
  .p-fileupload-files {
    word-break: break-word;
  }
  .autocomplete-project-element {
    background-color: #51b5a5 !important;
  }
  .form-legend-container {
    margin-top: 5px;
    margin-left: 5px;
  }
  .form-legend-row {
    display: flex;
    flex-direction: row;
  }
  .form-legend-box {
    display: inline-block;
    min-width: 20px;
    max-width: 20px;
    height: 15px;
    background-color: grey;
    margin-top: 3px;
    margin-right: 5px;
  }
  .form-legend-box.darkblue {
    background-color: #1e2351;
  }
  .form-legend-box.lightblue {
    background-color: #51b5a5;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    background: 0 none;
    padding: 0;
    text-align: center;
    width: calculateRem(30px);
    border: 0 none;
    color: $textColor;
    span {
      font-size: calculateRem(30px);
      line-height: calculateRem(20px);
      position: relative;
      top: calculateRem(-3px);
      opacity: 0.6;
      transition: opacity 0.3s ease;
    }
    &:hover,
    &:focus,
    &:active {
      span {
        opacity: 1;
      }
    }
    &.btn-row-delete {
      position: relative;
      top: calculateRem(4px);
      width: 100%;
    }
  }
  .trosace {
    position: relative;
    height: calculateRem(90px);
    min-width: calculateRem(145px);
    background: url("/assets/images/rosace.png") no-repeat center center;
    span {
      position: absolute;
    }
    .tnorth,
    .tsouth {
      width: 100%;
      left: 0;
      text-align: center;
    }
    .tnorth {
      top: 0;
    }
    .tsouth {
      bottom: 0;
    }
    .teast,
    .twest {
      top: 50%;
      margin-top: calculateRem(-11px);
    }
    .teast {
      left: 50%;
      margin-left: calculateRem(30px);
    }
    .twest {
      right: 50%;
      margin-right: calculateRem(30px);
    }
  }
  .described-extent {
    .open-btn {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 0 0 20px 0;
    }
  }
  .description-column {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 0px;
    }
  }
  .description-type-row {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    .description-type-elt {
      input[type="radio"] {
        display: none;
      }
      input[type="radio"] + label {
        font-size: calculateRem(15px);
        background-color: rgb(245,245,245);
        /* background-color: rgb(225,225,225); */
        border: solid 1px grey;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0px;
        transition: 0.25s all ease;
      }
      input[type="radio"]:checked + label {
        background-color: rgb(245,245,245);
        font-weight: bold;
      }
    }
    .description-type-elt:first-child {
      input[type="radio"] + label {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    .description-type-elt:last-child {
      input[type="radio"] + label {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    .description-type-elt:hover {
      cursor: pointer;
    }
  }
  .drop-files {
    .drop-zone {
      cursor: pointer;
      border: $commonBorder 2px dashed;
      background: $contentBg;
      border-radius: 5px;
      height: 150px;
      width: 100%;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      p,
      .drop-icon {
        pointer-events: none;
      }
      .drop-icon {
        border: 3px solid $commonBorder;
        background: $commonBg;
        border-radius: 5em;
        height: 5em;
        width: 5em;
      }
      .fa {
        font-size: 2.5em;
        position: relative;
        top: -3px;
      }
      &.dragover {
        background-color: $commonBg;
        border-color: $themeColor;
      }
    }
    .file-list {
      list-style-type: none;
      padding: 0;
      margin: calculateRem(10px) 0 0;
      li {
        border-bottom: thin solid $commonBorder;
        padding: 0 0 0 10px;
        &:last-child {
          border-bottom: 0 none;
        }
        &.invalid {
          color: $errorColor;
        }
        button {
          font-size: 2em;
          line-height: 0.6em;
          padding: 8px 10px;
        }
      }
      .download-link {
        color: $buttonBg;
        cursor: pointer;
      }
    }
  }
}

.p-datepicker-year {
  border-style: none;
  margin-bottom: 5px !important;
}
.p-datepicker-year:focus {
  outline: none;
}