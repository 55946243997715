/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/source-sans-pro-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../assets/fonts/source-sans-pro-v11-latin-regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-regular.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-regular.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/source-sans-pro-v11-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../assets/fonts/source-sans-pro-v11-latin-italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/source-sans-pro-v11-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../assets/fonts/source-sans-pro-v11-latin-700.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-700.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-700.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-700.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/source-sans-pro-v11-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldItalic"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/source-sans-pro-v11-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../assets/fonts/source-sans-pro-v11-latin-600.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-600.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-600.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-600.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/source-sans-pro-v11-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold Italic"), local("SourceSansPro-SemiBoldItalic"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "multicons";
  src: url("../assets/fonts/multicons.eot?in0sj");
  src: url("../assets/fonts/multicons.eot?in0sj#iefix") format("embedded-opentype"), url("../assets/fonts/multicons.ttf?in0sj") format("truetype"), url("../assets/fonts/multicons.woff?in0sj") format("woff"), url("../assets/fonts/multicons.svg?in0sj#multicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mult {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "multicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mult-alea:before {
  content: "\e900";
}

body {
  font-family: "Open Sans", "Source Sans Pro", Arial, sans-serif;
  font-size: 0.9375rem;
  color: #444;
  overflow: hidden;
}
body .white-with-border {
  color: white !important;
  font-weight: bold;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.btn {
  font-size: 1rem;
  outline: 0;
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:focus, .btn:active {
  box-shadow: none;
}
.btn .fa,
.btn .far,
.btn .fas {
  margin-right: 0.3125rem;
}

.btn-primary,
.btn-default,
.btn-danger,
.btn-add {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.btn-danger.normal-padding {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.btn-primary {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #51b5a5;
  border-color: #51b5a5;
  color: #fff;
  outline: 0;
  font-weight: 600;
}
.btn-primary:disabled {
  background-color: #51b5a5;
  border-color: #51b5a5;
  color: #fff;
}
.btn-primary:not(:disabled):focus, .btn-primary:not(:disabled):hover {
  background-color: #3f9486;
  border-color: #3f9486;
  color: #fff;
}
.btn-primary:not(:disabled):active, .btn-primary:not(:disabled):active:focus, .btn-primary:not(:disabled):active:hover {
  background-color: #3f9486;
  border-color: #3f9486;
  color: #fff;
  box-shadow: none;
}

.show > .btn-primary, .btn-primary:active {
  background-color: #3f9486 !important;
  border-color: #3f9486 !important;
}

.btn-add {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
  outline: 0;
  font-weight: 600;
}
.btn-add:disabled {
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
}
.btn-add:not(:disabled):focus, .btn-add:not(:disabled):hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
}
.btn-add:not(:disabled):active, .btn-add:not(:disabled):active:focus, .btn-add:not(:disabled):active:hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
  box-shadow: none;
}

.btn-default {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #ffffff;
  border-color: #a3a4ab;
  color: #666;
  outline: 0;
}
.btn-default:disabled {
  background-color: #ffffff;
  border-color: #a3a4ab;
  color: #666;
}
.btn-default:not(:disabled):focus, .btn-default:not(:disabled):hover {
  background-color: #e6e6e6;
  border-color: #a3a4ab;
  color: #4d4d4d;
}
.btn-default:not(:disabled):active, .btn-default:not(:disabled):active:focus, .btn-default:not(:disabled):active:hover {
  border-color: #a3a4ab;
  box-shadow: none;
}

.badge-primary {
  background-color: #51b5a5;
}

.badge-primary.selected {
  color: white !important;
  background-color: #3f9486;
}

.show-more-or-less-btn {
  border: 1px;
  background-color: #00619b;
  color: rgb(240, 240, 240);
  font-size: 1rem;
  border-radius: 3px;
}

.dropdown-toggle:after {
  display: none;
}
.dropdown-toggle .fa-chevron-down {
  margin-left: 0.4375rem;
  top: 0.4375rem;
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.dropdown-menu {
  padding: 0;
}
.dropdown-menu.download-dropdown-menu {
  min-width: unset;
}
.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]) {
  color: #444;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):focus {
  background: #ecedee;
  text-decoration: none;
}
.dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active:hover, .dropdown-menu .dropdown-item:active:focus, .dropdown-menu .dropdown-item.active:hover, .dropdown-menu .dropdown-item.active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active:focus {
  background: #1e2351;
  color: #fff;
  text-decoration: none;
}
.dropdown-menu .dropdown-item.item-danger,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger {
  color: #da2323;
}
.dropdown-menu .dropdown-item.item-danger:hover, .dropdown-menu .dropdown-item.item-danger:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:focus {
  color: #da2323;
}
.dropdown-menu .dropdown-item.item-danger:active, .dropdown-menu .dropdown-item.item-danger.active, .dropdown-menu .dropdown-item.item-danger:active:hover, .dropdown-menu .dropdown-item.item-danger:active:focus, .dropdown-menu .dropdown-item.item-danger.active:hover, .dropdown-menu .dropdown-item.item-danger.active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active:focus {
  background: #da2323;
  color: #fff;
}
.dropdown-menu .dropdown-item:first-child,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dropdown-menu .dropdown-item:last-child,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.form-control {
  height: auto;
  border: 0.0625rem solid #bcbccb;
}
.form-control:focus {
  box-shadow: none;
  border-color: #bcbccb;
}
.form-control .hidden {
  display: none;
}

.edit-fields-form.ng-submitted.ng-invalid .ng-invalid {
  border-color: #da2323;
}

.edit-fields-form.ng-submitted.ng-invalid .ng-invalid .form-control {
  border-color: #da2323;
}

.edit-fields-form.custom-submit.ng-invalid .ng-invalid {
  border-color: #da2323;
}

.edit-fields-form.custom-submit.ng-invalid .ng-invalid .form-control {
  border-color: #da2323;
}

.p-inputtext {
  padding: 0.375rem 0.75rem;
}

textarea.form-control {
  resize: vertical;
  height: 5.625rem;
}

.form-check {
  padding: 0;
}
.form-check .form-check-label {
  margin-bottom: 3px;
  cursor: pointer;
  position: relative;
}
.form-check .form-check-input {
  display: none;
}

.d-flex > .h-100 {
  min-height: 0;
}

.p-autocomplete-multiple.form-control {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.p-autocomplete-multiple .p-autocomplete-token {
  background: #1e2351;
  color: #fff;
  border-radius: 4px;
  cursor: default;
  display: flex;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-top: 0.125em;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}
.p-autocomplete-multiple .p-autocomplete-multiple-container.p-inputtext {
  padding: 0;
}

.p-autocomplete-panel {
  background: white;
  z-index: 1052 !important;
  border: 1px solid rgba(200, 200, 200, 0.4);
}

.p-autocomplete-list-item:hover {
  background: #ecedee;
}

.p-autocomplete-token-label {
  position: relative;
  margin-right: 2em;
}

.p-autocomplete-token-icon {
  position: relative;
  text-align: center;
}
.p-autocomplete-token-icon::before {
  content: "\e90b";
}

.pi-chevron-down:before {
  content: "\e906";
}

.p-fileupload-content .p-messages-error {
  border: 0 none;
  padding: 0 2.1875rem 0 0;
  margin: 0;
}
.p-fileupload-content .p-messages-error .p-messages-close {
  position: absolute;
  top: -0.25rem;
  right: 0;
  font-size: 1.875rem;
}
.p-fileupload-content .p-messages-error .p-messages-icon {
  display: none;
}

.p-fileupload-buttonbar {
  padding: 0;
}
.p-fileupload-buttonbar .p-fileupload-choose {
  padding: 0.5em;
}
.p-fileupload-buttonbar .p-fileupload-choose .p-button-icon-left {
  left: 1em;
}

.p-button-icon-only {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #51b5a5;
  border-color: #51b5a5;
  color: #fff;
  outline: 0;
  height: auto;
  border: none;
  border-radius: 0 0.25rem 0.25rem 0;
  position: relative;
}
.p-button-icon-only:disabled {
  background-color: #51b5a5;
  border-color: #51b5a5;
  color: #fff;
}
.p-button-icon-only:not(:disabled):focus, .p-button-icon-only:not(:disabled):hover {
  background-color: #3f9486;
  border-color: #3f9486;
  color: #fff;
}
.p-button-icon-only:not(:disabled):active, .p-button-icon-only:not(:disabled):active:focus, .p-button-icon-only:not(:disabled):active:hover {
  background-color: #3f9486;
  border-color: #3f9486;
  color: #fff;
  box-shadow: none;
}

.date-form-group .p-button-icon-only {
  height: auto;
}

legend {
  font-size: 1.2rem;
}

.grid {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 5px;
}

p-inputswitch {
  line-height: 0;
}

.p-inputswitch {
  background: #bcbccb;
  border-radius: 17px;
  width: 3em;
  height: 1.75em;
}
.p-inputswitch .p-inputswitch-slider {
  background: white;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  top: 3px;
  left: 3px;
}
.p-inputswitch.p-inputswitch-checked {
  background: #1e2351;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  left: 21px;
}
.p-inputswitch.p-disabled {
  opacity: 0.6;
}

.p-dropdown .p-dropdown-label {
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
}

.p-dropdown-panel {
  background: white;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}
.p-dropdown-panel .p-dropdown-filter {
  line-height: 1.1em;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}

.p-datepicker {
  background: white;
  width: 300px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  padding: 0.2em;
}

.p-datepicker-header {
  position: relative;
  padding: 0.5em 0;
}

.p-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.p-datepicker th {
  padding: 0.5em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.p-datepicker td {
  padding: 0.5em;
  text-align: center;
  border: 0;
}

.p-disabled {
  color: #444 !important;
}

a,
a:not([href]):not([tabindex]),
.link-style,
.p-datepicker td:not(.p-datepicker-other-month) > span {
  cursor: pointer;
  color: #51b5a5;
  text-decoration: none;
  transition: color 0.25s ease;
}
a:hover, a:focus, a:active, a:active:hover, a:active:focus,
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):active,
a:not([href]):not([tabindex]):active:hover,
a:not([href]):not([tabindex]):active:focus,
.link-style:hover,
.link-style:focus,
.link-style:active,
.link-style:active:hover,
.link-style:active:focus,
.p-datepicker td:not(.p-datepicker-other-month) > span:hover,
.p-datepicker td:not(.p-datepicker-other-month) > span:focus,
.p-datepicker td:not(.p-datepicker-other-month) > span:active,
.p-datepicker td:not(.p-datepicker-other-month) > span:active:hover,
.p-datepicker td:not(.p-datepicker-other-month) > span:active:focus {
  text-decoration: underline;
  color: #10132c;
}
a.btn-icon:hover, a.btn-icon:focus, a.btn-icon:active, a.btn-icon:active:hover, a.btn-icon:active:focus,
a:not([href]):not([tabindex]).btn-icon:hover,
a:not([href]):not([tabindex]).btn-icon:focus,
a:not([href]):not([tabindex]).btn-icon:active,
a:not([href]):not([tabindex]).btn-icon:active:hover,
a:not([href]):not([tabindex]).btn-icon:active:focus,
.link-style.btn-icon:hover,
.link-style.btn-icon:focus,
.link-style.btn-icon:active,
.link-style.btn-icon:active:hover,
.link-style.btn-icon:active:focus,
.p-datepicker td:not(.p-datepicker-other-month) > span.btn-icon:hover,
.p-datepicker td:not(.p-datepicker-other-month) > span.btn-icon:focus,
.p-datepicker td:not(.p-datepicker-other-month) > span.btn-icon:active,
.p-datepicker td:not(.p-datepicker-other-month) > span.btn-icon:active:hover,
.p-datepicker td:not(.p-datepicker-other-month) > span.btn-icon:active:focus {
  text-decoration: none;
}

html,
body,
app-root,
.main,
#login {
  height: 100%;
}

h1 {
  font-size: 1.75rem;
  margin: 0.1875rem 0 1.9375rem;
}

h2 {
  font-size: 1.5625rem;
  margin: 0.1875rem 0 1.75rem;
}

hr {
  border-color: #e0e0e0;
}

section {
  position: relative;
  overflow: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

label .fa-question-circle,
legend .fa-question-circle {
  font-size: 0.9em;
  padding: 0.25rem;
  position: relative;
  top: -0.0625rem;
}
label .fa-question-circle:before,
legend .fa-question-circle:before {
  position: relative;
  top: 0.0625rem;
}

.nowrap {
  white-space: nowrap;
}

.menu-tooltip {
  margin-left: calc(4.0625rem - 16.25rem);
}

.nl2br {
  white-space: pre-wrap;
}

.neutral-link {
  color: #888;
}
.neutral-link:hover, .neutral-link:focus, .neutral-link:active, .neutral-link:active:hover, .neutral-link:active:focus {
  text-decoration: underline;
  color: #444;
}

.alert .fa-info-circle {
  margin-right: 0.3125rem;
}
.alert ul {
  margin: 0;
  padding: 0 0 0 1.25rem;
}

.title-button-container {
  margin-top: 0.1875rem;
}

.trosace {
  position: relative;
  height: 5.625rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.trosace span {
  position: absolute;
}
.trosace .tnorth,
.trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.trosace .tnorth {
  top: 0;
}
.trosace .tsouth {
  bottom: 0;
}
.trosace .teast,
.trosace .twest {
  top: 50%;
  margin-top: -0.6875rem;
}
.trosace .teast {
  left: 50%;
  margin-left: 1.875rem;
}
.trosace .twest {
  right: 50%;
  margin-right: 1.875rem;
}

.main,
#login {
  padding: 0;
}

.page {
  position: relative;
  overflow: auto;
  background: url(/assets/images/background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page > ng-component {
  height: 100%;
  min-height: 0;
  position: relative;
}
.page .page-language-choice {
  padding: 1.3125rem 2.1875rem 0;
}
.page .page-citation-choice {
  padding: 1.3125rem 2.1875rem 0;
}
.page .page-citation-choice .btn.badge {
  margin-right: 0.3125rem;
}
.page .page-citation-choice .btn.badge.selected {
  color: #212529;
  text-decoration: none;
}
.page .page-citation-choice .citation-nav-bar {
  margin-bottom: 5px;
}
.page .page-citation-choice .citation {
  padding: 0.5rem;
  background-color: white;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.page .page-citation-choice .citation blockquote {
  margin-bottom: 0px;
  line-height: 1.2;
}
.page .options-buttons-row {
  padding: 1.3125rem;
}
.page .publication-asked {
  padding-top: 0;
  padding-bottom: 2.1875rem;
  padding-left: 1.3125rem;
  padding-right: 1.3125rem;
}
.page .page-header-and-content {
  #overflow-y: scroll;
}
.page .page-header-and-content .page-header {
  padding-top: 0;
}
.page .page-header-and-content .page-content {
  overflow: unset;
  height: fit-content;
}
.page .page-header-and-content .page-content .subcontent {
  max-height: unset;
  overflow: unset;
}
.page .page-header {
  padding: 1.3125rem 2.1875rem 0;
}
.page .page-header .ml-3:first-child {
  margin-left: 0px !important;
}
.page .page-content {
  padding: 0 2.1875rem 2.1875rem;
  height: 100%;
  overflow: auto;
}
.page > app-public-metadata-detail {
  height: 100%;
  min-height: 0;
}

.btn-icon {
  border: 0 none;
  background: transparent;
  font-size: 1.1rem;
  padding: 0.5rem 0.3125rem;
}
.btn-icon:focus {
  box-shadow: none;
}
.btn-icon .fa {
  margin: 0;
}

.subcontent {
  background: #ffffff;
  padding: 1.125rem 1.25rem;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
  max-height: 100%;
  overflow: auto;
}
.subcontent #publication-guide-image {
  max-width: min(100%, 500px);
}

.publication-asked .col {
  padding: 0px;
}
.publication-asked .col h2 {
  margin-bottom: 0.5rem;
}

.informations-page-content {
  overflow: unset !important;
}

.informations-subcontent {
  max-height: unset;
  overflow: unset;
  margin-bottom: 2.1875rem;
}
.informations-subcontent small {
  font-size: 85%;
  color: rgb(100, 100, 100);
}
.informations-subcontent .vertical-list {
  margin-bottom: 0px;
}

.metadata-field {
  position: relative;
}
.metadata-field .alert-danger {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 0.1875rem 0.625rem;
  box-shadow: 0 0.0625rem 0.25rem rgba(20, 20, 20, 0.4);
}
.metadata-field .alert-danger p {
  margin-bottom: 0;
}
.metadata-field .thematics-section .alert-danger, .metadata-field .keywords-section .alert-danger {
  position: unset;
}
.metadata-field .col .alert-danger {
  width: calc(100% - 30px);
}
.metadata-field .tab-like {
  border-bottom: 0.1875rem transparent solid;
  position: relative;
  top: 0.0625rem;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
}
.metadata-field .tab-like:hover, .metadata-field .tab-like:active, .metadata-field .tab-like:focus {
  border-bottom-color: #bcbccb;
}
.metadata-field .tab-like.active {
  border-bottom-color: #1e2351;
}
.metadata-field .file-tabs-container {
  border-bottom: 0.0625rem #bcbccb solid;
}
.metadata-field .btn-close {
  padding: 0;
  line-height: 0;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  color: #444;
}
.metadata-field .btn-close:hover, .metadata-field .btn-close:active, .metadata-field .btn-close:focus {
  opacity: 1;
}
.metadata-field .btn-close span {
  font-size: 2rem;
  line-height: 0.6rem;
}
.metadata-field .file-size-error {
  color: #da2323;
}

.list-counter {
  font-size: 0.9375rem;
  margin: 0.625rem 0;
  color: #888;
}

.filter-field-container .form-control {
  padding: 0.0625rem 0.9375rem;
  height: auto;
  width: 9.375rem;
}

.data-table {
  position: relative;
}
.data-table .data-title {
  font-size: 1.125rem;
}
.data-table .table {
  margin-bottom: 0.1875rem;
}
.data-table .table th,
.data-table .table td {
  font-size: 0.8125rem;
  vertical-align: middle;
}
.data-table .table thead {
  background: #f5f6fa;
}
.data-table .table thead th {
  color: #888;
  border: 0 none;
  text-transform: uppercase;
  white-space: nowrap;
}
.data-table .table thead th span {
  white-space: normal;
}
.data-table .table thead th.sortable {
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome and Opera */
}
.data-table .table thead th.sortable.text-center > span {
  position: relative;
  left: 0.3125rem;
}
.data-table .table thead th.sortable:after {
  content: " ";
  position: relative;
  width: 0;
  height: 0;
  border: 0.3125rem transparent solid;
  left: 0.75rem;
}
.data-table .table thead th.sortable.asc:after {
  top: 0.75rem;
  border-top-color: #888;
}
.data-table .table thead th.sortable.desc:after {
  top: -0.875rem;
  border-bottom-color: #888;
}
.data-table .table tbody td {
  color: #585858;
  border-top: 0 none;
  border-bottom: 0.0625rem solid #e0e0e0;
}
.data-table .table tbody td.btn-icon-container {
  padding: 0;
}
.data-table .table tbody td.btn-icon-container .dropdown-toggle:after {
  display: none;
}
.data-table .table tbody td.btn-icon-container .dropdown-menu {
  margin-top: -0.625rem;
  margin-left: -0.3125rem;
}
.data-table .table tbody td .btn-icon {
  transition: color 0.25s ease;
  color: #a6a6a6;
  font-size: 0.75rem;
  width: 2.1875rem;
  line-height: 1.6875rem;
}
.data-table .table tbody td .btn-icon:disabled {
  color: #a6a6a6;
}
.data-table .table tbody td .btn-icon:not(:disabled):focus, .data-table .table tbody td .btn-icon:not(:disabled):hover {
  color: #444;
}
.data-table .table tbody td .btn-icon:not(:disabled):active, .data-table .table tbody td .btn-icon:not(:disabled):active:focus, .data-table .table tbody td .btn-icon:not(:disabled):active:hover {
  color: #444;
}
.data-table .table .group-sub-item {
  margin-left: 10px;
}
.data-table .actions {
  width: 3.125rem;
}

#admin-groups .column-title {
  display: flex;
  flex-direction: column;
}
#admin-groups .column-title h1 {
  margin-bottom: 0.5rem;
}

.status-pending {
  color: goldenrod;
}

.status-accepted {
  color: green;
}

.status-refused {
  color: red;
}

.load-icon {
  display: inline-block;
  font-size: 10px;
  margin: 0px;
  text-indent: -9999em;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #51b5a5;
  background: -moz-linear-gradient(left, #51b5a5 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #51b5a5 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #51b5a5 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #51b5a5 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #51b5a5 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.load-icon:before {
  width: 50%;
  height: 50%;
  background: #51b5a5;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.load-icon:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.pagination {
  margin: 0.625rem 0 0;
}
.pagination .page-link {
  color: #444;
  border-color: #d8dbe2;
  outline: 0;
  font-size: 0.8125rem;
}
.pagination .page-link:active, .pagination .page-link:focus, .pagination .page-link:active:hover, .pagination .page-link:active:focus {
  box-shadow: none;
  text-decoration: none;
  color: #444;
}
.pagination .active .page-link {
  background-color: #f5f6fa;
  color: #444;
  border-color: #d8dbe2;
}
.pagination .disabled .page-link {
  color: #dfdfe4;
  border-color: #d8dbe2;
}

.right-panel {
  position: fixed;
  top: 70px;
  right: 0;
  background: white;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
  width: 28.75rem;
  height: calc(100% - 70px);
  overflow: auto;
  padding: 1.25rem;
  z-index: 12;
}
.right-panel h2 {
  font-size: 1.6875rem;
  margin: 0 0 1.875rem;
  padding-right: 3.125rem;
}
.right-panel .btn.fa-times {
  position: absolute;
  top: 0.5rem;
  right: 1.25rem;
  font-size: 2.5rem;
  transition: color 0.25s ease;
  color: #bcbccb;
}
.right-panel .btn.fa-times:disabled {
  color: #bcbccb;
}
.right-panel .btn.fa-times:not(:disabled):focus, .right-panel .btn.fa-times:not(:disabled):hover {
  color: #444;
}
.right-panel .btn.fa-times:not(:disabled):active, .right-panel .btn.fa-times:not(:disabled):active:focus, .right-panel .btn.fa-times:not(:disabled):active:hover {
  color: #444;
}
.right-panel label {
  font-size: 1rem;
}

.bbox-container {
  padding: 0.625rem 0.9375rem 0.9375rem;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}
.bbox-container label,
.bbox-container .form-control {
  font-size: 0.875rem;
}
.bbox-container label {
  margin-bottom: 0.3125rem;
}
.bbox-container .form-control {
  height: auto;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}
.bbox-container .little-form-group {
  margin-bottom: 0.3125rem;
}

.extents-list {
  padding-left: 15px;
}

.confirm-modal .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.confirm-modal .close {
  box-shadow: none;
  outline: 0;
  padding: 0 0.75rem;
  font-size: 3.125rem;
  margin-top: -0.625rem;
}
.confirm-modal .modal-title {
  font-size: 1.375rem;
}

.results-table-modal .modal-dialog {
  margin: 0;
  top: 2.5%;
  left: 2.5%;
  max-width: 95%;
  height: 95%;
}
.results-table-modal .modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}
.results-table-modal .modal-dialog .modal-content ng-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.results-table-modal .modal-dialog .modal-content .modal-body {
  height: 100%;
  overflow: auto;
}

.fa-circle-notch {
  animation-name: spinner;
  -o-animation-name: spinner;
  -ms-animation-name: spinner;
  -webkit-animation-name: spinner;
  -moz-animation-name: spinner;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.modal-backdrop {
  /* bug fix - no overlay */
  display: none;
}

.loader {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}
.loader.visible {
  opacity: 1;
  visibility: visible;
}
.loader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 11.25rem;
  height: 2.5rem;
  margin-left: 16.25rem;
  margin-top: 70px;
}
.loader .lds-ripple .brand {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.625rem;
  bottom: 100%;
  color: #ffffff;
  text-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.4);
}
.loader .lds-ripple .round {
  position: absolute;
  top: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: linear;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.4);
}
.loader .lds-ripple .round.round-1 {
  left: 0;
  animation-name: lds-ellipsis1;
}
.loader .lds-ripple .round.middle-round {
  animation-name: lds-ellipsis2;
  z-index: 1;
}
.loader .lds-ripple .round.round-2 {
  left: 0;
}
.loader .lds-ripple .round.round-3 {
  left: 2.5rem;
}
.loader .lds-ripple .round.round-4 {
  left: 5rem;
}
.loader .lds-ripple .round.round-5 {
  left: 7.5rem;
}
.loader .lds-ripple .round.round-6 {
  left: 10rem;
  animation-name: lds-ellipsis3;
}
.loader.menu-collapsed .lds-ripple {
  margin-left: 4.0625rem;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.5rem, 0);
  }
}
.ol-dragbox {
  border-color: #1e2351;
  background-color: rgba(255, 255, 255, 0.2);
  border-width: 0.1875rem;
}

.ol-control button {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  background-color: #1e2351;
  transition: background-color 0.25s ease;
}
.ol-control button:hover, .ol-control button:focus, .ol-control button:active {
  background-color: #1e2351;
}

.ol-scale-line,
.ol-mouse-position {
  background: #1e2351;
  opacity: 0.9;
}

.ol-scale-line {
  padding: 0.375rem 0;
  width: 10.3125rem;
}
.ol-scale-line .ol-scale-line-inner {
  margin: 0 auto;
}

.ol-mouse-position {
  top: auto;
  right: auto;
  left: 11.25rem;
  bottom: 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.3125rem;
  color: #fff;
  width: 11.875rem;
  text-align: center;
}

.ol-overviewmap {
  left: 0.5rem;
  bottom: 2.75rem;
}

.ol-attribution {
  right: 0.5rem;
  bottom: 3rem;
}

.ol-rotate {
  top: 4.25rem;
  right: 3.25rem;
}

.ol-zoom-to-bbox-control {
  left: 0.5rem;
  right: auto;
  top: 5rem;
}

.ol-full-screen {
  top: auto;
  right: 0.5rem;
  bottom: 0.5rem;
}

.ol-layer-switcher-control {
  top: 4.25rem;
  right: 0.5rem;
}

.ol-layer-legend-control {
  top: 7rem;
  right: 0.5rem;
}

.ol-layer-measure-control {
  top: 9.75rem;
  right: 0.5rem;
}

.ol-layer-export-control {
  top: 12.5rem;
  right: 0.5rem;
}

.reset-points-control {
  top: 15.25rem;
  right: 0.5rem;
  z-index: 1;
}

.map-control-selector {
  z-index: 1;
  padding: 0;
}
.map-control-selector > div {
  opacity: 0.9;
  background: #1e2351;
  padding: 0.1875rem;
  padding-left: 0.3125rem;
  border-radius: 0.1875rem;
}
.map-control-selector label {
  color: #fff;
  margin: 0;
  white-space: nowrap;
}
.map-control-selector .form-control {
  height: auto;
  padding: 0 0.625rem;
  border: 0 none;
  border-radius: 0.1875rem;
}

.destination-selector {
  bottom: 0.5rem;
  left: 37.375rem;
}

.bottom-controls {
  position: absolute;
  bottom: 0.5rem;
  left: 23.625rem;
}
.bottom-controls .ol-control {
  position: relative;
  top: 0;
  left: 0;
}
.bottom-controls .ol-control + .ol-control {
  margin-left: 0.5rem;
}

.map-modal .modal-dialog {
  margin: 0;
  top: 2.5%;
  left: 2.5%;
  max-width: 95%;
  height: 95%;
}
.map-modal .modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}
.map-modal .modal-dialog .modal-content ng-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map-modal .modal-dialog .modal-content .modal-body {
  height: 100%;
  overflow: auto;
  padding: 0.3125rem 0.3125rem 0;
}

#map-modal.no-modal-bottom {
  padding-bottom: 0.3125rem;
}
#map-modal .close {
  position: absolute;
  outline: 0;
  z-index: 5;
  right: 0;
  top: 0;
  font-size: 4rem;
  background: white;
  padding: 0 0.9375rem;
  border-bottom-left-radius: 0.25rem;
}

.map-container {
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.bbox-help {
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 1.25rem;
  left: 0;
  width: 100%;
  z-index: 1;
}
.bbox-help p {
  margin: 0 auto;
  background: white;
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
}

.destination-type {
  position: absolute;
  text-align: left;
  top: 8px;
  left: 48px;
  width: 20%;
  z-index: 1;
}
.destination-type input[type=radio] {
  display: none;
}
.destination-type input[type=radio] + label {
  font-size: 1rem;
  background-color: rgb(245, 245, 245);
  border: solid 1px grey;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  transition: 0.25s all ease;
}
.destination-type input[type=radio]:checked + label {
  background-color: rgb(245, 245, 245);
  font-weight: bold;
}

.autocomplete {
  top: 40px;
  left: 60px;
  max-width: 300px;
  width: 45%;
  z-index: 1;
  padding: 3px;
}
.autocomplete > div {
  background: #1e2351;
  opacity: 0.9;
  padding: 3px;
  border-radius: 4px;
}
.autocomplete .btn {
  background: transparent;
  position: absolute;
  top: 6px;
  margin: 0;
  color: #444;
  opacity: 0.7;
  transition: opacity 0.25s ease;
}
.autocomplete .btn:hover, .autocomplete .btn:active, .autocomplete .btn:focus {
  background: transparent;
  opacity: 1;
}
.autocomplete .search-btn {
  left: 6px;
}
.autocomplete .reset-btn {
  right: 6px;
}
.autocomplete .form-control {
  border: 0 none;
  border-radius: 4px;
  height: auto;
  padding: 2px 30px;
}
.autocomplete .search-list {
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0;
  top: 100%;
  padding: 0 3px 3px;
  margin-top: -3px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}
.autocomplete .search-list ul {
  background: #1e2351;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 4px;
}
.autocomplete .search-list li a,
.autocomplete .search-list li a:not([href]):not([tabindex]) {
  display: block;
  padding: 1px 5px;
  font-size: 14px;
  color: #fff;
}
.autocomplete .search-list li a:hover, .autocomplete .search-list li a:active, .autocomplete .search-list li a:focus, .autocomplete .search-list li a:active:hover, .autocomplete .search-list li a:active:focus,
.autocomplete .search-list li a:not([href]):not([tabindex]):hover,
.autocomplete .search-list li a:not([href]):not([tabindex]):active,
.autocomplete .search-list li a:not([href]):not([tabindex]):focus,
.autocomplete .search-list li a:not([href]):not([tabindex]):active:hover,
.autocomplete .search-list li a:not([href]):not([tabindex]):active:focus {
  background: #00619b;
  color: #fff;
}
.autocomplete:hover .search-list {
  background: rgba(255, 255, 255, 0.6);
}

.layer-switcher {
  position: absolute;
  top: 4.25rem;
  right: 0;
  z-index: 10;
  overflow: auto;
  background: white;
  border: 0.0625rem #bcbccb solid;
  box-shadow: -0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.4);
  width: 50%;
  max-width: 43.75rem;
  transition: right 0.5s ease;
}
.layer-switcher .btn-icon {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.layer-switcher .btn-icon:hover, .layer-switcher .btn-icon:focus, .layer-switcher .btn-icon:active {
  opacity: 1;
}
.layer-switcher .layer-switcher-header {
  padding: 0 0.625rem;
  border-bottom: 0.125rem #bcbccb solid;
}
.layer-switcher .layer-switcher-header h4 {
  margin: 0;
}
.layer-switcher .layer-switcher-header .form-control {
  height: auto;
  width: auto;
  padding: 0.0625rem 0.625rem;
  max-width: 12.5rem;
}
.layer-switcher .layer-switcher-header label {
  margin: 0;
}
.layer-switcher .layerswitcher-section-header {
  margin: 0;
  padding: 0.3125rem 0.9375rem 0.4375rem;
  border-bottom: 0.125rem #bcbccb solid;
  font-size: 1.125rem;
}
.layer-switcher .layer-switcher-rows-container {
  max-height: 18.75rem;
  overflow: auto;
}
.layer-switcher .layer-switcher-rows-container + .layerswitcher-section-header {
  border-top: 0.125rem #bcbccb solid;
}
.layer-switcher .no-layers {
  padding: 0.1875rem 0.625rem;
}
.layer-switcher .trosace {
  position: relative;
  height: 4.75rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.layer-switcher .trosace span {
  position: absolute;
}
.layer-switcher .trosace .tnorth,
.layer-switcher .trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.layer-switcher .trosace .tnorth {
  top: 0;
}
.layer-switcher .trosace .tsouth {
  bottom: 0;
}
.layer-switcher .trosace .teast,
.layer-switcher .trosace .twest {
  top: 50%;
  margin-top: -0.5625rem;
}
.layer-switcher .trosace .teast {
  left: 6.25rem;
}
.layer-switcher .trosace .twest {
  right: 6.25rem;
}

.layer-legends {
  top: 7rem;
  z-index: 9;
}
.layer-legends .card {
  border: 0 none;
}
.layer-legends .card-header {
  padding: 0;
}

.layer-measure {
  top: 9.75rem;
  max-width: 15.625rem;
  z-index: 8;
}
.layer-measure .measure-buttons .btn-container {
  background: #bcbccb;
  padding: 0.0625rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: auto;
}
.layer-measure .measure-buttons .btn {
  font-size: 1.5rem;
  width: 2.8125rem;
  opacity: 1;
  transition: color 0.25s ease, background-color 0.25s ease;
  color: #888;
  background-color: #ebebeb;
}
.layer-measure .measure-buttons .btn:focus, .layer-measure .measure-buttons .btn:hover, .layer-measure .measure-buttons .btn:active, .layer-measure .measure-buttons .btn:active:focus, .layer-measure .measure-buttons .btn:active:hover {
  color: #1e2351;
}
.layer-measure .measure-buttons .btn.active {
  background-color: #fefefe;
  color: #444;
}
.layer-measure .measure-buttons .btn-length {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}
.layer-measure .measure-buttons .btn-area {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.layer-measure .measure-result strong {
  font-weight: 600;
}

.layer-export {
  top: 12.5rem;
  max-width: 15.625rem;
  z-index: 7;
}

.layer-switcher-row {
  padding: 0 0.625rem;
  border-bottom: 0.0625rem #bcbccb solid;
  background: white;
}
.layer-switcher-row .btn-icon {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.layer-switcher-row .btn-icon:hover, .layer-switcher-row .btn-icon:focus, .layer-switcher-row .btn-icon:active {
  opacity: 1;
}
.layer-switcher-row .btn-icon.drag-handler {
  cursor: grab;
}
.layer-switcher-row.gu-transit {
  background: #bcbccb;
  opacity: 0.5;
}
.layer-switcher-row.gu-transit * {
  visibility: hidden;
}
.layer-switcher-row.gu-mirror .btn-icon.drag-handler {
  cursor: grabbing;
}
.layer-switcher-row:last-child {
  border-bottom: 0 none;
}
.layer-switcher-row .btn-close {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6rem;
}
.layer-switcher-row .title-link,
.layer-switcher-row .title-result {
  position: relative;
  top: -0.0625rem;
  display: inline-block;
  padding: 0.1875rem 0;
}
.layer-switcher-row .title-link:not([href]):not([tabindex]):hover, .layer-switcher-row .title-link:not([href]):not([tabindex]):focus, .layer-switcher-row .title-link:not([href]):not([tabindex]):active {
  text-decoration: underline;
}
.layer-switcher-row .form-check-label {
  padding-left: 1.4375rem;
}
.layer-switcher-row .form-check-label:before {
  width: 1.25rem;
  height: 1.25rem;
}
.layer-switcher-row .form-check-label:after {
  left: 0.25rem;
  top: 0.3125rem;
}
.layer-switcher-row .slider-container {
  min-width: 3.125rem;
  position: relative;
  top: -0.1875rem;
}
.layer-switcher-row .ng5-slider {
  margin: 0;
}
.layer-switcher-row .ng5-slider .ng5-slider-bar-wrapper {
  height: auto;
  padding: 0;
  margin: 0;
}
.layer-switcher-row .ng5-slider .ng5-slider-pointer {
  width: 0.875rem;
  height: 0.875rem;
  top: -0.3125rem;
  outline: 0rem;
  background-color: #1e2351;
  transition: background-color 0.25s ease;
}
.layer-switcher-row .ng5-slider .ng5-slider-pointer:hover {
  background-color: #10132c;
}
.layer-switcher-row .ng5-slider .ng5-slider-pointer:after {
  display: none;
}
.layer-switcher-row .ng5-slider .ng5-slider-selection {
  background-color: #1e2351;
}
.layer-switcher-row .layer-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out, border-top-width 0.25s ease-out;
  border-top: 0 #bcbccb dotted;
  padding: 0 0.875rem;
  margin: 0 -0.625rem;
  font-size: 0.9em;
}
.layer-switcher-row .layer-details.open {
  max-height: 31.25rem;
  border-top-width: 0.0625rem;
  transition: max-height 0.25s ease-in;
}
.layer-switcher-row .layer-details dt {
  padding-bottom: 0.125rem;
  margin-bottom: 0.1875rem;
  border-bottom: 0.0625rem #1e2351 solid;
}
.layer-switcher-row .layer-details dt:first-child {
  padding-top: 0.3125rem;
}
.layer-switcher-row .layer-details dd:last-child {
  padding-bottom: 0.3125rem;
}
.layer-switcher-row .layer-details dd address {
  margin: 0;
}
.layer-switcher-row .layer-details dd address strong {
  font-weight: 600;
}

.result-popup {
  position: absolute;
  top: -6.5625rem;
  left: 1.125rem;
  background: #f0f0f7;
  padding: 0.9375rem 0 0.9375rem 0.9375rem;
  border-radius: 0.375rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.result-popup:before, .result-popup:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
}
.result-popup:before {
  border: transparent solid 0.625rem;
  border-right-color: rgba(0, 0, 0, 0.4);
  margin-top: -0.625rem;
  left: -1.25rem;
}
.result-popup:after {
  border: transparent solid 0.5625rem;
  border-right-color: #f0f0f7;
  margin-top: -0.5625rem;
  left: -1.125rem;
}
.result-popup dl {
  margin: 0;
  width: 12.5rem;
  height: 11.25rem;
  overflow: auto;
}

#header {
  color: #fff;
  background: #1e2351;
  height: 70px;
  padding: 0;
  z-index: 10;
}
#header .navbar {
  height: 70px;
}
#header a {
  color: #fff;
}
#header .btn-icon {
  position: relative;
  top: -0.125rem;
}
#header .navbar-brand {
  margin-left: -0.9375rem;
  margin-right: 0;
  width: 16.25rem;
}
#header .navbar-brand img {
  max-height: 80.5px;
}
#header .dropdown-menu {
  width: 100%;
}
#header .dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  padding: 0.3125rem 1.25rem;
  color: #444;
  text-decoration: none;
}
#header .dropdown-menu .dropdown-item:active {
  background: #1e2351;
  color: #fff;
}
#header .search-group {
  position: relative;
  border: solid 1px #1e2351;
  border-radius: 5px;
}
#header .search-group .search-icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  left: 1.25rem;
  top: 0.5625rem;
  color: #bcbccb;
  font-size: 1.1rem;
  z-index: 10;
  pointer-events: none;
}
#header .search-group .dropdown-menu {
  margin-top: -0.125rem;
}
#header .search-group .fa-caret-right {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  color: #bcbccb;
  z-index: 10;
  pointer-events: none;
}
#header .search {
  font-size: 0.8125rem;
  padding-left: 2.8125rem;
  border: 0 none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 2.125rem;
}
#header .search + .input-group-append {
  margin-left: 0;
}
#header .search-btn,
#header .type-btn {
  background: #ffffff;
  color: #444;
}
#header .search-btn {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #444;
  outline: 0;
  width: 11.25rem;
  border: 0.0625rem #ffffff solid;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#header .search-btn:disabled {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #444;
}
#header .search-btn:not(:disabled):focus, #header .search-btn:not(:disabled):hover {
  background-color: #ecedee;
  border-color: #ecedee;
  color: #444;
}
#header .search-btn:not(:disabled):active, #header .search-btn:not(:disabled):active:focus, #header .search-btn:not(:disabled):active:hover {
  background-color: #ecedee;
  border-color: #ecedee;
  color: #444;
  box-shadow: none;
}
#header .type-btn {
  position: relative;
  border: 0 none;
  width: 10.9375rem;
  text-align: left;
  font-size: 0.8125rem;
  padding: 0.3125rem 1.25rem;
}
#header .type-btn:before {
  content: " ";
  width: 0;
  height: 1.5rem;
  border-left: 0.0625rem #bcbccb solid;
  position: absolute;
  left: 0;
  top: 0.4375rem;
}
#header .type-btn:after {
  content: "\f0d7";
  opacity: 0.4;
  font-size: 0.875rem;
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  margin-top: -0.375rem;
}
#header .btn-icon {
  transition: color 0.25s ease;
  color: #bcbccb;
  margin-left: 0.625rem;
}
#header .btn-icon:disabled {
  color: #bcbccb;
}
#header .btn-icon:not(:disabled):focus, #header .btn-icon:not(:disabled):hover {
  color: #fff;
}
#header .btn-icon:not(:disabled):active, #header .btn-icon:not(:disabled):active:focus, #header .btn-icon:not(:disabled):active:hover {
  color: #fff;
}
#header .fa-bell {
  position: relative;
}
#header .fa-bell:after {
  content: " ";
  display: none;
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem #1d2251 solid;
  border-radius: 0.625rem;
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
}
#header .fa-bell.alerts:after, #header .fa-bell.completed:after {
  display: block;
}
#header .fa-bell.alerts:after {
  background: #1e2351;
}
#header .fa-bell.completed:after {
  background: #00ad0d;
}
#header .dropdown-toggle:after {
  display: none;
}
#header .dropdown-menu {
  font-size: 0.875rem;
  min-width: 18.75rem;
  width: auto;
}
#header .dropdown-menu p {
  padding: 0.3125rem 1.25rem;
  font-weight: 600;
}
#header .dropdown-menu .dropdown-item .status {
  display: inline-block;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 100%;
  margin-right: 0.1875rem;
  position: relative;
  top: 0.0625rem;
  border: 0.0625rem #ffffff solid;
}
#header .dropdown-menu .dropdown-item .status.Submitted, #header .dropdown-menu .dropdown-item .status.Running {
  background: #1e2351;
}
#header .dropdown-menu .dropdown-item .status.Succeeded {
  background: #00ad0d;
}
#header .dropdown-menu .dropdown-item .status.Failed {
  background: #da2323;
}
#header .separator {
  position: relative;
  padding-left: 0.625rem;
  margin-left: 0.625rem;
}
#header .separator:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1.75rem;
  width: 0;
  margin-top: -0.9375rem;
  border-left: 0.0625rem #fff solid;
}
#header .account {
  padding-right: 3.125rem;
  position: relative;
  font-size: 0.8125rem;
}
#header .account:after {
  color: #fff;
  margin-left: 0.4375rem;
  top: 0.4375rem;
  line-height: 0.1rem;
  font-size: 1.0625rem;
}
#header .account .fa-user {
  position: absolute;
  right: 0;
  font-size: 1.875rem;
  overflow: hidden;
  border-radius: 100%;
  width: 2.375rem;
  top: 0;
  height: 2.375rem;
  text-align: center;
  background: rgb(197, 197, 197);
}
#header .account .fa-user:before {
  position: relative;
  top: 0.625rem;
}
#header .account + .dropdown-menu {
  margin-top: 0.625rem;
}
#header .account + .dropdown-menu .groups-container {
  margin: 0;
  padding: 0.3125rem 1.25rem;
  font-size: 0.9em;
  border-bottom: thin solid #bcbccb;
}
#header .account + .dropdown-menu .groups-container dd {
  margin: 0;
}
#header .account + .dropdown-menu .groups-container ul {
  padding-left: 1.1875rem;
  list-style-type: circle;
  margin: 0;
}

#footer {
  background: #1e2351;
}
#footer div {
  color: #fff;
  display: flex;
  justify-content: center;
}
#footer .navbar {
  justify-content: space-evenly;
}
#footer a {
  color: #fff;
}

#menu {
  width: 16.25rem;
  color: #fff;
  background: #1e2351;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease;
}
#menu .nav {
  width: 16.25rem;
}
#menu .nav-link {
  border-top: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  border-left: 0.5rem solid;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  outline: 0;
  padding: 1.125rem 1.25rem 1.125rem 0.75rem;
  text-align: left;
  width: 100%;
}
#menu .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
#menu .nav-link:not(:disabled):focus, #menu .nav-link:not(:disabled):hover {
  background-color: #00619b;
  border-color: #51b5a5;
  color: #fff;
}
#menu .nav-link:not(:disabled):active, #menu .nav-link:not(:disabled):active:focus, #menu .nav-link:not(:disabled):active:hover {
  background-color: #00619b;
  border-color: #00619b;
  color: #fff;
  box-shadow: none;
}
#menu .nav-link.active {
  background-color: #00619b;
  border-color: #51b5a5;
}
#menu .nav-link .fa {
  width: 1.25rem;
  margin-right: 0.3125rem;
  text-align: center;
  font-size: 1rem;
  color: #a5a4bf;
  position: relative;
  left: 0;
  transition: left 0.25s ease;
}
#menu .nav-link span {
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
#menu .nav-link .fa-chevron-left {
  transform: rotate(0);
  transition: left 0.25s ease, transform 0.3s ease;
}
#menu hr {
  border-color: #aaa;
  margin: 0.625rem 0;
}
#menu .app-version {
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
#menu .app-version a {
  color: #fff;
}
#menu .app-version a:hover, #menu .app-version a:focus, #menu .app-version a:active {
  color: #fff;
}
#menu.collapsed {
  width: 4.0625rem;
}
#menu.collapsed .nav-link span {
  opacity: 0;
  visibility: hidden;
}
#menu.collapsed .nav-link:hover .fa, #menu.collapsed .nav-link:active .fa, #menu.collapsed .nav-link:focus .fa, #menu.collapsed .nav-link.active .fa {
  left: 0.5rem;
}
#menu.collapsed .fa-chevron-left {
  transform: rotate(180deg);
}
#menu.collapsed .app-version {
  opacity: 0;
  visibility: hidden;
}

.breadcrumb {
  background-color: white;
  margin: 0;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
  position: relative;
  z-index: 10;
}
.breadcrumb .breadcrumb-item {
  font-size: 0.8125rem;
}
.breadcrumb .breadcrumb-item:before, .breadcrumb .breadcrumb-item.active {
  color: #a6a7ad;
}

.simple-map {
  width: 100%;
  height: 15.625rem;
  border: 0.0625rem #bcbccb solid;
}

.progress-bar {
  visibility: collapse;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}
.progress-bar.visible {
  opacity: 1;
  visibility: visible;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 3px;
  height: 3em;
  width: 100%;
}

.progress-block {
  padding: 2rem;
  color: #fff;
  background: #1e2351;
  border-radius: 5px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #a5a4bf;
}

.p-progressbar .p-progressbar-label {
  line-height: 3rem;
}

#projects .data-table .date {
  width: 5.9375rem;
}
#projects .data-table .owner {
  width: 16.25rem;
}
#projects .data-table .views {
  width: 4.6875rem;
}
#projects .data-table .fa-lock,
#projects .data-table .fa-globe-africa,
#projects .data-table .fa-user-friends {
  margin-right: 0.625rem;
  width: 0.9375rem;
  position: relative;
  color: #888;
}
#projects .data-table .fa-globe-africa {
  top: 0.0625rem;
}
#projects .data-table .fa-user-friends {
  left: -0.125rem;
}
#projects .subcontent {
  overflow: unset;
  max-height: unset;
}

#datas .subcontent {
  overflow: unset;
  max-height: unset;
}

#links .data-table .url {
  width: 21.875rem;
}
#links .data-table .url a {
  display: block;
  width: 20.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#links .subcontent {
  overflow: unset;
  max-height: unset;
}

#project-detail h1 {
  margin-bottom: 0.625rem;
}
#project-detail h1 .fa {
  color: #888;
  margin-right: 0.625rem;
  font-size: 0.9em;
}
#project-detail label[for=language] {
  font-size: 1.15em;
}
#project-detail .description {
  color: #888;
  font-size: 0.8125rem;
  margin: 0;
}
#project-detail .page-content {
  overflow: unset;
}
#project-detail .subcontent {
  padding: 0;
  overflow: unset;
  max-height: unset;
}
#project-detail .tab-content {
  border-color: #e9e9f0;
  padding: 0;
  position: relative;
  height: 100%;
}
#project-detail .tab-content .tab-pane {
  height: 100%;
}
#project-detail .tab-content .add-btn {
  position: absolute;
  bottom: 100%;
  right: 0.4375rem;
  padding: 0.3125rem 1.5625rem;
  margin-bottom: 0.4375rem;
}
#project-detail .nav-link {
  border: 0 none;
  border-bottom: 0.125rem transparent solid;
  padding: 0.75rem 1.875rem;
  color: #666;
  margin-right: 0.1875rem;
}
#project-detail .nav-link:focus, #project-detail .nav-link:hover {
  border-color: #666;
  text-decoration: none;
}
#project-detail .nav-link:active, #project-detail .nav-link.active, #project-detail .nav-link:active:hover, #project-detail .nav-link:active:focus, #project-detail .nav-link.active:hover, #project-detail .nav-link.active:focus {
  color: #1e2351;
  border-color: #1e2351;
  text-decoration: none;
}
#project-detail .data-table {
  margin: 0 -1rem;
  overflow: none;
}
#project-detail .data-table .actions {
  width: 3.125rem;
}
#project-detail .data-table .url {
  width: 21.875rem;
}
#project-detail .data-table .url a {
  display: block;
  width: 20.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#project-detail .title-button-container button {
  white-space: nowrap;
}
#project-detail .meta-detail {
  overflow: none;
}
#project-detail .page-citation-choice label {
  font-size: 1.15em;
}
#project-detail .copy-to-clipboard {
  display: inline-block;
  background-color: rgb(220, 220, 220);
  color: rgb(40, 40, 40);
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: all 0.15s;
}
#project-detail .copy-to-clipboard:hover {
  cursor: pointer;
  background-color: rgb(200, 200, 200);
  color: rgb(20, 20, 20);
}

.meta-detail h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
}
.meta-detail label[for=language] {
  font-size: 1.15em;
}
.meta-detail .accordion-title {
  padding-bottom: 0.1875rem;
  margin-bottom: 0.1875rem;
  border-bottom: 0.125rem solid #1e2351;
}
.meta-detail .accordion-content {
  max-height: 18.75rem;
  overflow: auto;
  transition: max-height 0.3s linear;
}
.meta-detail .accordion-content.closed {
  max-height: 0;
}
.meta-detail .accordion-btn {
  padding: 0 0.625rem 0 0;
}
.meta-detail .accordion-btn span {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #444;
}
.meta-detail .accordion-btn .fa {
  transform: rotate(0deg);
  transition: transform 0.3s linear, opacity 0.3s ease;
  opacity: 0.6;
}
.meta-detail .accordion-btn .fa.rotated {
  transform: rotate(180deg);
}
.meta-detail .accordion-btn:hover .fa, .meta-detail .accordion-btn:focus .fa, .meta-detail .accordion-btn:active .fa {
  opacity: 1;
}
.meta-detail .contacts {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.meta-detail .contacts li {
  margin-bottom: 5px;
}
.meta-detail .contacts li:last-child {
  margin-bottom: 0;
}
.meta-detail .contactIdentifiers {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.meta-detail .contactIdentifiers li {
  margin-bottom: 0px;
}
.meta-detail .contactIdentifiers li:last-child {
  margin-bottom: 0;
}
.meta-detail .page-citation-choice label {
  font-size: 1.15em;
}
.meta-detail .copy-to-clipboard {
  display: inline-block;
  background-color: rgb(220, 220, 220);
  color: rgb(40, 40, 40);
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: all 0.15s;
}
.meta-detail .copy-to-clipboard:hover {
  cursor: pointer;
  background-color: rgb(200, 200, 200);
  color: rgb(20, 20, 20);
}

#data-detail label[for=language] {
  font-size: 1.15em;
}

#account .subsection:not(:last-child) {
  margin-bottom: 1.25rem;
}
#account .subsection h2 {
  font-size: 1.5625rem;
  margin: 0 0 0.625rem;
}
#account .def-line dt,
#account .def-line dd {
  display: inline-block;
}
#account .group-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}
#account .group-list li {
  line-height: 1.1em;
}
#account .group-list li strong {
  font-size: 1.1em;
  line-height: 1.2em;
}
#account .group-list li:last-child p {
  margin-bottom: 0;
}

#landpage {
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
  overflow: unset;
}
#landpage button {
  font-size: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
}
#landpage .page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#landpage .page-header img {
  max-height: 200px;
}
#landpage .page-content {
  height: auto;
  margin-top: 5rem;
  overflow: unset;
}
#landpage .page-content img {
  max-height: 80px;
}

#resources .ol-zoom-extent {
  margin-top: 0.625rem;
}
#resources .ol-zoom-extent .fa {
  position: relative;
  top: 0.0625rem;
}
#resources .destination-selector {
  bottom: 2.625rem;
  left: 0.5rem;
}
#resources .right-panel {
  width: 31.25rem;
  right: -31.25rem;
  transition: right 0.15s linear, width 0.15s linear;
  overflow: visible;
}
#resources .right-panel:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  width: 0.625rem;
  z-index: 1;
}
#resources .right-panel .toggle-btn {
  position: absolute;
  top: 4.5rem;
  right: 100%;
  background: white;
  border-radius: 0;
  padding: 0.75rem 0.625rem;
  font-size: 1.375rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
  border-right: #bcbccb 0.0625rem solid;
}
#resources .right-panel .toggle-btn i {
  transform: rotate(0deg);
  transition: transform 0.15s ease;
}
#resources .right-panel.opened {
  right: 0;
}
#resources .right-panel.opened .toggle-btn i {
  transform: rotate(180deg);
}
#resources .right-panel.wide {
  width: calc(80% - 16.25rem);
}
#resources .right-panel.wide .wide-btn .fa.fa-arrow-left {
  left: 0.75rem;
}
#resources .right-panel.wide .wide-btn .fa.fa-arrow-right {
  left: -0.75rem;
}
#resources .right-panel .hidden {
  display: none;
}
#resources .row {
  min-height: 0.0625rem;
}
#resources h1 {
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
}
#resources .search-filters {
  padding: 1.25rem;
  overflow: hidden;
}
#resources .search-filters .search-message {
  font-size: 1rem;
  color: #1e2351;
  font-weight: bold;
}
#resources .search-filters .search-filters-content {
  overflow: auto;
}
#resources .search-filters .extent-btn {
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.125rem 0;
}
#resources .search-filters .extent-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
}
#resources .search-filters .extent-backdrop.active {
  display: block;
}
#resources .search-filters .extent-backdrop p {
  text-align: center;
  position: absolute;
  left: 0;
  top: 28%;
  font-size: 0.875rem;
  margin-top: -8px;
  width: 100%;
}
#resources .search-filters .btn-clear-extent {
  position: absolute;
  top: -0.5rem;
  right: 0;
  font-size: 1.875rem;
  line-height: 1.25rem;
  z-index: 1;
  padding: 0.3125rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
#resources .search-filters .btn-clear-extent:hover, #resources .search-filters .btn-clear-extent:focus, #resources .search-filters .btn-clear-extent:active {
  opacity: 1;
}
#resources .search-filters .btn-clear-extent i {
  position: relative;
  top: -0.1875rem;
}
#resources .search-filters .rosace {
  position: relative;
  height: 4.6875rem;
  background: url("/assets/images/rosace.png") no-repeat scroll center center;
}
#resources .search-filters .rosace div {
  position: absolute;
  font-size: 0.75rem;
}
#resources .search-filters .rosace .north,
#resources .search-filters .rosace .south {
  left: 0;
  width: 100%;
  text-align: center;
}
#resources .search-filters .rosace .north {
  bottom: 50%;
  margin-bottom: 1.25rem;
}
#resources .search-filters .rosace .south {
  top: 50%;
  margin-top: 1.25rem;
}
#resources .search-filters .rosace .east,
#resources .search-filters .rosace .west {
  top: 50%;
  margin-top: -0.5rem;
}
#resources .search-filters .rosace .east {
  left: 50%;
  margin-left: 1.5625rem;
}
#resources .search-filters .rosace .west {
  right: 50%;
  margin-right: 1.5625rem;
}
#resources .results-table {
  position: absolute;
  padding: 1.25rem 0 0;
  background: white;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  transition: left 0.15s linear;
  overflow: hidden;
  z-index: 1;
}
#resources .results-table.opened {
  left: 0;
}
#resources .results-table h2 {
  padding-left: 1.25rem;
}
#resources .results-table .results-table-content {
  overflow: auto;
}
#resources .results-table .list-counter {
  margin: 0;
  padding: 0 1.25rem;
}
#resources .results-table .wide-btn {
  padding-left: 0.9375rem;
  padding-right: 1.25rem;
}
#resources .results-table .wide-btn .fa {
  font-size: 0.625rem;
  position: relative;
  top: -0.125rem;
  margin: 0;
}
#resources .results-table .wide-btn .fa.fa-arrow-left {
  margin-right: 0.1875rem;
}
#resources .results-table .wide-btn .fa.fa-arrow-right {
  margin-right: 0.625rem;
}
#resources .data-table tr td {
  position: relative;
}
#resources .data-table tr td:before, #resources .data-table tr td:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  border-top: transparent 0.1875rem solid;
}
#resources .data-table tr td:before {
  top: -0.125rem;
}
#resources .data-table tr td:after {
  bottom: -0.125rem;
}
#resources .data-table tr td:first-child {
  border-left: 0.625rem transparent solid;
}
#resources .data-table tr td:first-child:before, #resources .data-table tr td:first-child:after {
  left: -0.625rem;
  width: calc(100% + 0.625rem);
}
#resources .data-table tr.hovered td:before, #resources .data-table tr.hovered td:after {
  border-top-color: #1e2351;
}
#resources .data-table tr.hovered td:first-child {
  border-left-color: #1e2351;
}
#resources .data-table .name .fa {
  width: 0.9375rem;
  text-align: center;
  margin-right: 0.3125rem;
  color: #888;
}
#resources .data-table .type {
  width: 5.625rem;
}
#resources .data-table .date {
  width: 5.9375rem;
}
#resources .data-table .owner {
  width: 16.25rem;
}
#resources .left-ressources-panel,
#resources .right-ressources-panel {
  background: #ffffff;
}
#resources .left-ressources-panel.hidden,
#resources .right-ressources-panel.hidden {
  display: none;
}
#resources .left-ressources-panel {
  overflow-y: auto;
  position: relative;
}
#resources .left-resources-panel-content {
  padding: 1.3125rem 2.1875rem;
}
#resources .right-ressources-panel {
  overflow: hidden;
}

.resource-details {
  font-size: 0.875rem;
  margin: 0;
  width: 25rem;
}
.resource-details dl {
  margin: 0;
}
.resource-details dt,
.resource-details dd {
  padding: 0.1875rem 0.625rem;
  color: #585858;
}
.resource-details dt {
  background: #f5f6fa;
  border-top: 0.0625rem #e0e0e0 solid;
}
.resource-details dt:first-child {
  border-top: 0 none;
}
.resource-details dd:last-child {
  margin: 0;
}
.resource-details .project-extent {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.25rem;
  background: #ffffff;
  border: 0.0625rem #e0e0e0 solid;
  padding: 0 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: color 0.3s ease;
}
.resource-details .project-extent:hover, .resource-details .project-extent:active, .resource-details .project-extent:focus {
  color: #1e2351;
}

.rights-modal .modal-dialog {
  max-width: 90%;
  height: 85%;
}
.rights-modal .modal-dialog .modal-content,
.rights-modal .modal-dialog ng-component {
  height: 100%;
}
.rights-modal .modal-dialog .modal-body {
  overflow: auto;
}
.rights-modal .form-group {
  border-bottom: #bcbccb thin solid;
  padding-bottom: 0.9375rem;
}
.rights-modal .form-group:last-child {
  border-bottom: 0 none;
  padding-bottom: 0;
}
.rights-modal .form-control {
  padding: 0.125rem 0.3125rem;
  height: auto;
}
.rights-modal select.form-control {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}
.rights-modal .table .btn-cell,
.rights-modal .table .btn-cell-head {
  padding: 0;
}
.rights-modal .table .btn-cell .btn-icon {
  font-size: 1.875rem;
  line-height: 1.875rem;
  padding: 0.75rem 1.0625rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.rights-modal .table .btn-cell .btn-icon:hover, .rights-modal .table .btn-cell .btn-icon:focus, .rights-modal .table .btn-cell .btn-icon:active {
  opacity: 1;
}
.rights-modal .table .btn-cell-head .btn-icon {
  padding: 0.625rem 1rem;
  color: #1e2351;
  transition: color 0.3s ease;
}
.rights-modal .table .btn-cell-head .btn-icon:hover, .rights-modal .table .btn-cell-head .btn-icon:focus, .rights-modal .table .btn-cell-head .btn-icon:active {
  color: #10132c;
}

.edit-form {
  background: white;
  padding: 1.25rem;
  overflow: none;
}
.edit-form .form-separator {
  margin: 0.9375rem 0 1.875rem;
  border-color: #bcbccb;
}
.edit-form .form-title {
  font-size: 1.5rem;
}

.edit-fields-form {
  /* .ng-invalid {
    border-color: $errorColor;
    .form-control {
      border-color: $errorColor;
    }
  } */
  /* .ng-invalid {
    border-color: $formControlBorder;
  } */
}
.edit-fields-form.ng-submitted .ng-invalid {
  border-color: #da2323;
}
.edit-fields-form.ng-submitted .ng-invalid .form-control {
  border-color: #da2323;
}
.edit-fields-form label[for=language] {
  font-size: 1.15em;
}
.edit-fields-form .vertical-list {
  display: flex;
  flex-direction: column;
}
.edit-fields-form .vertical-list li {
  margin-bottom: 0px;
}
.edit-fields-form .column {
  padding: 0px;
}
.edit-fields-form .pop-up-container {
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  font-size: 0.8125rem;
}
.edit-fields-form .pop-up-container .arrow-up {
  position: relative;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #51b5a5;
  left: 10px;
}
.edit-fields-form .pop-up-container .arrow-up:after {
  content: "";
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent #51b5a5;
  position: absolute;
  top: 1px;
  left: -10px;
}
.edit-fields-form .pop-up-container .pop-up-content {
  border: solid 1px #51b5a5;
  border-radius: 0.25rem;
  background-color: white;
  max-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 5px;
}
.edit-fields-form .pop-up-container .pop-up-content .pop-up-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px;
  font-size: 0.875rem;
  color: white;
  background-color: #51b5a5;
}
.edit-fields-form .pop-up-container .pop-up-content .pop-up-header .pop-up-close-button {
  font-size: 1.25rem;
  color: white;
  background-color: transparent;
  border: none;
  margin-right: 3px;
}
.edit-fields-form .pop-up-container .pop-up-content .pop-up-header .pop-up-close-button:hover {
  font-weight: bold;
}
.edit-fields-form .pop-up-container .pop-up-content .pop-up-body {
  padding: 5px;
}
.edit-fields-form .pop-up-container.hidden {
  display: none;
}
.edit-fields-form fieldset {
  position: relative;
  border: thin #bcbccb solid;
  padding: 0.9375rem;
  border-radius: 0.1875rem;
}
.edit-fields-form fieldset legend {
  position: absolute;
  font-size: 1rem;
  background: white;
  z-index: 1;
  width: auto;
  padding: 0 0.625rem;
  top: -0.8125rem;
  left: 0.625rem;
}
.edit-fields-form fieldset label {
  margin-right: 0.625rem;
}
.edit-fields-form fieldset.with-close-btn .btn-close {
  top: -0.5rem;
  right: -0.9375rem;
  background: white;
}
.edit-fields-form .form-group label small {
  display: block;
  color: #888;
}
.edit-fields-form .form-group .alert {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  z-index: 1;
}
.edit-fields-form .form-group .alert.not-absolute {
  position: unset;
}
.edit-fields-form .form-group .alert p:last-child {
  margin-bottom: 0;
}
.edit-fields-form .form-group p-autocomplete > span.dropdown {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.edit-fields-form .form-group p-autocomplete > span.dropdown ul {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.edit-fields-form .form-group p-autocomplete > span.dropdown button {
  background-color: #1e2351;
  border-color: #1e2351;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  color: #fff;
  height: 2.5em !important;
}
.edit-fields-form .form-group p-autocomplete ul {
  width: 100%;
}
.edit-fields-form .form-group p-autocomplete ul li {
  /* width: 100%; */
}
.edit-fields-form .form-group p-autocomplete ul li input {
  width: 100%;
}
.edit-fields-form .p-button-icon-only {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
  outline: 0;
  height: auto;
  border: none;
  border-radius: 0 0.25rem 0.25rem 0;
  position: relative;
}
.edit-fields-form .p-button-icon-only:disabled {
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
}
.edit-fields-form .p-button-icon-only:not(:disabled):focus, .edit-fields-form .p-button-icon-only:not(:disabled):hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
}
.edit-fields-form .p-button-icon-only:not(:disabled):active, .edit-fields-form .p-button-icon-only:not(:disabled):active:focus, .edit-fields-form .p-button-icon-only:not(:disabled):active:hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
  box-shadow: none;
}
.edit-fields-form .file-size-error {
  color: #da2323;
}
.edit-fields-form .p-fileupload-buttonbar {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
  outline: 0;
  border-radius: 0.25rem;
}
.edit-fields-form .p-fileupload-buttonbar:disabled {
  background-color: #1e2351;
  border-color: #1e2351;
  color: #fff;
}
.edit-fields-form .p-fileupload-buttonbar:not(:disabled):focus, .edit-fields-form .p-fileupload-buttonbar:not(:disabled):hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
}
.edit-fields-form .p-fileupload-buttonbar:not(:disabled):active, .edit-fields-form .p-fileupload-buttonbar:not(:disabled):active:focus, .edit-fields-form .p-fileupload-buttonbar:not(:disabled):active:hover {
  background-color: #10132c;
  border-color: #10132c;
  color: #fff;
  box-shadow: none;
}
.edit-fields-form .p-fileupload-content {
  padding: 0 0 0 0.5rem;
}
.edit-fields-form .p-fileupload-files {
  word-break: break-word;
}
.edit-fields-form .autocomplete-project-element {
  background-color: #51b5a5 !important;
}
.edit-fields-form .form-legend-container {
  margin-top: 5px;
  margin-left: 5px;
}
.edit-fields-form .form-legend-row {
  display: flex;
  flex-direction: row;
}
.edit-fields-form .form-legend-box {
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  height: 15px;
  background-color: grey;
  margin-top: 3px;
  margin-right: 5px;
}
.edit-fields-form .form-legend-box.darkblue {
  background-color: #1e2351;
}
.edit-fields-form .form-legend-box.lightblue {
  background-color: #51b5a5;
}
.edit-fields-form .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  background: 0 none;
  padding: 0;
  text-align: center;
  width: 1.875rem;
  border: 0 none;
  color: #444;
}
.edit-fields-form .btn-close span {
  font-size: 1.875rem;
  line-height: 1.25rem;
  position: relative;
  top: -0.1875rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.edit-fields-form .btn-close:hover span, .edit-fields-form .btn-close:focus span, .edit-fields-form .btn-close:active span {
  opacity: 1;
}
.edit-fields-form .btn-close.btn-row-delete {
  position: relative;
  top: 0.25rem;
  width: 100%;
}
.edit-fields-form .trosace {
  position: relative;
  height: 5.625rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.edit-fields-form .trosace span {
  position: absolute;
}
.edit-fields-form .trosace .tnorth,
.edit-fields-form .trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.edit-fields-form .trosace .tnorth {
  top: 0;
}
.edit-fields-form .trosace .tsouth {
  bottom: 0;
}
.edit-fields-form .trosace .teast,
.edit-fields-form .trosace .twest {
  top: 50%;
  margin-top: -0.6875rem;
}
.edit-fields-form .trosace .teast {
  left: 50%;
  margin-left: 1.875rem;
}
.edit-fields-form .trosace .twest {
  right: 50%;
  margin-right: 1.875rem;
}
.edit-fields-form .described-extent .open-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 0 0 20px 0;
}
.edit-fields-form .description-column {
  display: flex;
  flex-direction: column;
}
.edit-fields-form .description-column label {
  margin-bottom: 0px;
}
.edit-fields-form .description-type-row {
  display: flex;
  flex-direction: row;
  margin-left: 0px;
}
.edit-fields-form .description-type-row .description-type-elt input[type=radio] {
  display: none;
}
.edit-fields-form .description-type-row .description-type-elt input[type=radio] + label {
  font-size: 0.9375rem;
  background-color: rgb(245, 245, 245);
  /* background-color: rgb(225,225,225); */
  border: solid 1px grey;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  transition: 0.25s all ease;
}
.edit-fields-form .description-type-row .description-type-elt input[type=radio]:checked + label {
  background-color: rgb(245, 245, 245);
  font-weight: bold;
}
.edit-fields-form .description-type-row .description-type-elt:first-child input[type=radio] + label {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.edit-fields-form .description-type-row .description-type-elt:last-child input[type=radio] + label {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.edit-fields-form .description-type-row .description-type-elt:hover {
  cursor: pointer;
}
.edit-fields-form .drop-files .drop-zone {
  cursor: pointer;
  border: #bcbccb 2px dashed;
  background: #f0f0f7;
  border-radius: 5px;
  height: 150px;
  width: 100%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.edit-fields-form .drop-files .drop-zone p,
.edit-fields-form .drop-files .drop-zone .drop-icon {
  pointer-events: none;
}
.edit-fields-form .drop-files .drop-zone .drop-icon {
  border: 3px solid #bcbccb;
  background: white;
  border-radius: 5em;
  height: 5em;
  width: 5em;
}
.edit-fields-form .drop-files .drop-zone .fa {
  font-size: 2.5em;
  position: relative;
  top: -3px;
}
.edit-fields-form .drop-files .drop-zone.dragover {
  background-color: white;
  border-color: #1e2351;
}
.edit-fields-form .drop-files .file-list {
  list-style-type: none;
  padding: 0;
  margin: 0.625rem 0 0;
}
.edit-fields-form .drop-files .file-list li {
  border-bottom: thin solid #bcbccb;
  padding: 0 0 0 10px;
}
.edit-fields-form .drop-files .file-list li:last-child {
  border-bottom: 0 none;
}
.edit-fields-form .drop-files .file-list li.invalid {
  color: #da2323;
}
.edit-fields-form .drop-files .file-list li button {
  font-size: 2em;
  line-height: 0.6em;
  padding: 8px 10px;
}
.edit-fields-form .drop-files .file-list .download-link {
  color: #51b5a5;
  cursor: pointer;
}

.p-datepicker-year {
  border-style: none;
  margin-bottom: 5px !important;
}

.p-datepicker-year:focus {
  outline: none;
}

.admin-layout {
  background: white;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
}
.admin-layout .admin-nav {
  background: #1e2351;
}
.admin-layout .admin-nav .navbar-brand,
.admin-layout .admin-nav .nav-link {
  color: #fff;
}
.admin-layout .admin-nav .nav-link {
  padding: 1rem 1rem 0.625rem;
  border-bottom: 0.375rem transparent solid;
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
  color: #fff;
}
.admin-layout .admin-nav .nav-link:hover, .admin-layout .admin-nav .nav-link:active, .admin-layout .admin-nav .nav-link:focus, .admin-layout .admin-nav .nav-link.active {
  color: #fff;
  text-decoration: none;
  background-color: #00619b;
  border-bottom-color: #51b5a5;
}
.admin-layout .status-dot {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  background: #da2323;
  border-radius: 50%;
}
.admin-layout .status-dot.active {
  background: #00ad0d;
}
.admin-layout .admin-page > ng-component {
  height: 100%;
}
.admin-layout .admin-page .page-header {
  padding: 0.9375rem 1.25rem 0;
}
.admin-layout .admin-page .page-content {
  padding: 0 1.25rem 1.25rem;
}
.admin-layout .admin-page .page-content .data-table {
  overflow: auto;
  max-height: 100%;
}
.admin-layout .admin-page .page-content .data-table .fa {
  margin-right: 0.3125rem;
}
.admin-layout .admin-page .page-content .data-table .delete-icon {
  font-size: 1.125rem;
  color: #da2323;
  margin: auto;
}
.admin-layout .admin-page .page-content .data-table .delete-icon:hover {
  color: #ae1c1c;
}
.admin-layout .admin-page .page-content .data-table th.name {
  width: 500px;
}
.admin-layout .admin-page .page-content .data-table td > div {
  height: 25px;
}
.admin-layout .admin-page .page-content .data-table td > div .flag-link {
  font-size: 16px;
}
.admin-layout .admin-page .page-content .data-table td > div .flag-link:hover {
  text-decoration: none;
}
.admin-layout .admin-page .page-content .data-table td > div .flag-link:not(:last-child) {
  margin-right: 5px;
}
.admin-layout .admin-page .page-content .data-table td.name-cell a {
  display: inline-block;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-layout .admin-page .page-content .data-table td.name-cell a em {
  color: unset;
}

#admin-group-manage .edit-form,
#user-search-admin .edit-form {
  max-width: 37.5rem;
}
#admin-group-manage h2,
#user-search-admin h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}
#admin-group-manage .group-content,
#user-search-admin .group-content {
  max-width: 43.75rem;
}
#admin-group-manage .user-list,
#user-search-admin .user-list {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto;
}
#admin-group-manage .user-list li .btn-icon,
#user-search-admin .user-list li .btn-icon {
  font-size: 1.875rem;
  line-height: 1.25rem;
  padding: 0.625rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
#admin-group-manage .user-list li .btn-icon:hover, #admin-group-manage .user-list li .btn-icon:focus, #admin-group-manage .user-list li .btn-icon:active,
#user-search-admin .user-list li .btn-icon:hover,
#user-search-admin .user-list li .btn-icon:focus,
#user-search-admin .user-list li .btn-icon:active {
  opacity: 1;
}

#admin-group-edit .edit-form {
  max-width: 37.5rem;
}