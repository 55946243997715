#admin-group-manage,
#user-search-admin {
  .edit-form {
    max-width: 37.5rem;
  }
  h2 {
    font-size: calculateRem(24px);
    margin-bottom: calculateRem(20px);
  }
  .group-content {
    max-width: calculateRem(700px);
  }
  .user-list {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: auto;
    li {
      .btn-icon {
        font-size: calculateRem(30px);
        line-height: calculateRem(20px);
        padding: calculateRem(10px);
        opacity: 0.6;
        transition: opacity .3s ease;
        &:hover,
        &:focus,
        &:active {
          opacity: 1;
        }
      }
    }
  }
}
