#resources {
  .ol-zoom-extent {
    margin-top: calculateRem(10px);
    .fa {
      position: relative;
      top: calculateRem(1px);
    }
  }
  .destination-selector {
    bottom: calculateRem(42px);
    left: calculateRem(8px);
  }
  .right-panel {
    width: calculateRem(500px);
    right: calculateRem(-500px);
    transition: right 0.15s linear, width 0.15s linear;
    overflow: visible;
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $commonBg;
      width: calculateRem(10px);
      z-index: 1;
    }
    .toggle-btn {
      position: absolute;
      top: calculateRem(72px);
      right: 100%;
      background: $commonBg;
      border-radius: 0;
      padding: calculateRem(12px) calculateRem(10px);
      font-size: calculateRem(22px);
      box-shadow: 0 0 calculateRem(5px) $commonShadow;
      border-right: $greyColor calculateRem(1px) solid;
      i {
        transform: rotate(0deg);
        transition: transform 0.15s ease;
      }
    }
    &.opened {
      right: 0;
      .toggle-btn {
        i {
          transform: rotate(180deg);
        }
      }
    }
    &.wide {
      width: calc(80% - #{$menuWidth});
      .wide-btn {
        .fa {
          &.fa-arrow-left {
            left: calculateRem(12px);
          }
          &.fa-arrow-right {
            left: calculateRem(-12px);
          }
        }
      }
    }
    .hidden {
      display: none;
    }
  }
  .row {
    min-height: calculateRem(1px); // trick to avoid overflow bug on Firefox/Safari
  }
  h1 {
    font-size: calculateRem(28px);
    margin-bottom: calculateRem(20px);
  }
  .search-filters {
    padding: calculateRem(20px);
    overflow: hidden;
    .search-message {
      font-size: calculateRem(16px);
      color: $themeColor;
      font-weight: bold;
    }
    .search-filters-content {
      overflow: auto;
    }
    .extent-btn {
      font-size: calculateRem(14px);
      font-weight: normal;
      padding: calculateRem(2px) 0;
    }
    .extent-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      background: $extentBackdropBg;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 2;
      &.active {
        display: block;
      }
      p {
        text-align: center;
        position: absolute;
        left: 0;
        top: 28%;
        font-size: calculateRem(14px);
        margin-top: -8px;
        width: 100%;
      }
    }
    .btn-clear-extent {
      position: absolute;
      top: calculateRem(-8px);
      right: 0;
      font-size: calculateRem(30px);
      line-height: calculateRem(20px);
      z-index: 1;
      padding: calculateRem(5px);
      opacity: 0.6;
      transition: opacity 0.3s ease;
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
      i {
        position: relative;
        top: calculateRem(-3px);
      }
    }
    .rosace {
      position: relative;
      height: calculateRem(75px);
      background: url("/assets/images/rosace.png") no-repeat scroll center center;
      div {
        position: absolute;
        font-size: calculateRem(12px);
      }
      .north,
      .south {
        left: 0;
        width: 100%;
        text-align: center;
      }
      .north {
        bottom: 50%;
        margin-bottom: calculateRem(20px);
      }
      .south {
        top: 50%;
        margin-top: calculateRem(20px);
      }
      .east,
      .west {
        top: 50%;
        margin-top: calculateRem(-8px);
      }
      .east {
        left: 50%;
        margin-left: calculateRem(25px);
      }
      .west {
        right: 50%;
        margin-right: calculateRem(25px);
      }
    }
  }
  .results-table {
    position: absolute;
    padding: calculateRem(20px) 0 0;
    background: $commonBg;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    transition: left 0.15s linear;
    overflow: hidden;
    z-index: 1;
    &.opened {
      left: 0;
    }
    h2 {
      padding-left: calculateRem(20px);
    }
    .results-table-content {
      overflow: auto;
    }
    .list-counter {
      margin: 0;
      padding: 0 calculateRem(20px);
    }
    .wide-btn {
      padding-left: calculateRem(15px);
      padding-right: calculateRem(20px);
      .fa {
        font-size: calculateRem(10px);
        position: relative;
        top: calculateRem(-2px);
        margin: 0;
        &.fa-arrow-left {
          margin-right: calculateRem(3px);
        }
        &.fa-arrow-right {
          margin-right: calculateRem(10px);
        }
      }
    }
  }
  .data-table {
    tr {
      td {
        position: relative;
        &:before,
        &:after {
          content: " ";
          position: absolute;
          left: 0;
          width: 100%;
          height: 0;
          border-top: transparent calculateRem(3px) solid;
        }
        &:before {
          top: calculateRem(-2px);
        }
        &:after {
          bottom: calculateRem(-2px);
        }
        &:first-child {
          border-left: calculateRem(10px) transparent solid;
          &:before,
          &:after {
            left: calculateRem(-10px);
            width: calc(100% + #{calculateRem(10px)});
          }
        }
      }
      &.hovered {
        td {
          &:before,
          &:after {
            border-top-color: $themeColor;
          }
          &:first-child {
            border-left-color: $themeColor;
          }
        }
      }
    }
    .name {
      .fa {
        width: calculateRem(15px);
        text-align: center;
        margin-right: calculateRem(5px);
        color: $projectIconColor;
      }
    }
    .type {
      width: calculateRem(90px);
    }
    .date {
      width: calculateRem(95px);
    }
    .owner {
      width: calculateRem(260px);
    }
  }
  .left-ressources-panel,
  .right-ressources-panel {
    background: $dataTableBg;
    &.hidden {
      display: none;
    }
  }
  .left-ressources-panel {
    overflow-y: auto;
    position: relative;
  }
  .left-resources-panel-content {
    padding: calculateRem(21px) calculateRem(35px);
  }
  .right-ressources-panel {
    overflow: hidden;
  }
}

.resource-details {
  font-size: calculateRem(14px);
  margin: 0;
  width: calculateRem(400px);
  dl {
    margin: 0;
  }
  dt,
  dd {
    padding: calculateRem(3px) calculateRem(10px);
    color: $dataTableTextColor;
  }
  dt {
    background: $dataTableHeaderBg;
    border-top: calculateRem(1px) $dataTableBorder solid;
    &:first-child {
      border-top: 0 none;
    }
  }
  dd:last-child {
    margin: 0;
  }
  .project-extent {
    position: absolute;
    top: 0;
    right: 0;
    font-size: calculateRem(20px);
    background: $defaultBtnBg;
    border: calculateRem(1px) $dataTableBorder solid;
    padding: 0 calculateRem(5px);
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: color .3s ease;
    &:hover,
    &:active,
    &:focus {
      color: $themeColor;
    }
  }
}
