// bootstrap overrides
body {
  font-family: "Open Sans", "Source Sans Pro", Arial, sans-serif;
  font-size: calculateRem(15px);
  color: $textColor;
  overflow: hidden;
  .white-with-border {
    color: white !important;
    font-weight: bold;
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  }
}

.btn {
  font-size: calculateRem(16px);
  outline: 0;
  &:not(:disabled):not(.disabled):active:focus,
  &:focus,
  &:active {
    box-shadow: none;
  }
  .fa,
  .far,
  .fas {
    margin-right: calculateRem(5px);
  }
}

.btn-primary,
.btn-default,
.btn-danger,
.btn-add {
  padding-left: calculateRem(28px);
  padding-right: calculateRem(28px);
}
.btn-danger.normal-padding {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-primary {
  @include btn-states($buttonBg, $textInverseColor, darken($buttonBg, 10%));
  font-weight: 600;
}
.show > .btn-primary , .btn-primary:active {
  background-color: #3f9486 !important;
  border-color: #3f9486 !important;
}

.btn-add {
  @include btn-states($menuBg, $textInverseColor, darken($menuBg, 10%));
  font-weight: 600;
}

.btn-default {
  @include btn-states(
    $defaultBtnBg,
    $btnDefaultColor,
    darken($defaultBtnBg, 10%),
    darken($btnDefaultColor, 10%),
    $btnDefaultBorder,
    $btnDefaultBorder,
    null,
    null,
    $btnDefaultBorder
  );
}

.badge-primary {
  background-color:$buttonBg;
}
.badge-primary.selected {
  color: white !important;
  background-color: darken($color: $buttonBg, $amount: 10%);
}

.show-more-or-less-btn {
  border: 1px;
  background-color: #00619b;
  color: rgb(240, 240, 240);
  font-size: calculateRem(16px);
  border-radius: 3px;
}

.dropdown-toggle {
  &:after {
    display: none;
  }
  .fa-chevron-down{
    margin-left: calculateRem(7px);
    top: calculateRem(7px);
    border: 0 none;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }


}

.dropdown-menu {
  &.download-dropdown-menu {
    min-width: unset;
  }
  padding: 0;
  .dropdown-item,
  .dropdown-item:not([href]):not([tabindex]) {
    color: $textColor;
    &:hover,
    &:focus {
      background: $dropdownHoverBg;
      text-decoration: none;
    }
    &:active,
    &.active,
    &:active:hover,
    &:active:focus,
    &.active:hover,
    &.active:focus {
      background: $themeColor;
      color: $textInverseColor;
      text-decoration: none;
    }

    &.item-danger {
      color: $errorColor;
      &:hover,
      &:focus {
        color: $errorColor;
      }
      &:active,
      &.active,
      &:active:hover,
      &:active:focus,
      &.active:hover,
      &.active:focus {
        background: $errorColor;
        color: $textInverseColor;
      }
    }
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.form-control {
  height: auto;
  border: calculateRem(1px) solid $formControlBorder;
  &:focus {
    box-shadow: none;
    border-color: $formControlBorder;
  }
  .hidden {
    display: none;
  }
}
.edit-fields-form.ng-submitted.ng-invalid .ng-invalid {
  border-color: $errorColor;
}
.edit-fields-form.ng-submitted.ng-invalid .ng-invalid .form-control {
  border-color: $errorColor;
}

.edit-fields-form.custom-submit.ng-invalid .ng-invalid {
  border-color: $errorColor;
}
.edit-fields-form.custom-submit.ng-invalid .ng-invalid .form-control {
  border-color: $errorColor;
}

.p-inputtext {
  padding: calculateRem(6px) calculateRem(12px);
}

textarea.form-control {
  resize: vertical;
  height: calculateRem(90px);
}

.form-check {
  padding: 0;

  .form-check-label {
    margin-bottom: 3px;
    cursor: pointer;
    position: relative;
  }
  .form-check-input {
    display: none;
  }
}

.d-flex {
  > .h-100 {
    // firefox
    min-height: 0;
  }
}

// primeng overrides
.p-autocomplete-multiple {
  &.form-control {
    padding-top: calculateRem(2px);
    padding-bottom: calculateRem(2px);
  }
  .p-autocomplete-token {
    background: $themeColor;
    color: $textInverseColor;
    border-radius: 4px;
    cursor: default;
    display: flex;
    vertical-align: middle;
    overflow: hidden;
    padding: 0.125em 0.5em;
    white-space: nowrap;
    position: relative;
    margin-top: 0.125em;
    margin-right: 0.125em;
    border: 0 none;
    font-size: .9em;
  }
  .p-autocomplete-multiple-container.p-inputtext {
    padding: 0;
  }
}
.p-autocomplete-panel {
  background: $commonBg;
  z-index: 1052 !important;
  border: 1px solid rgba(200, 200, 200, .4);
}
.p-autocomplete-list-item {
  &:hover {
    background: $dropdownHoverBg;
  }
}
.p-autocomplete-token-label {
  position: relative;
  margin-right: 2em;
}
.p-autocomplete-token-icon {
  position: relative;
  text-align: center;
  &::before{
    content: "\e90b";
  }
}
.pi-chevron-down:before {
  content: "\e906";
}
.p-fileupload-content {
  .p-messages-error {
    border: 0 none;
    padding: 0 calculateRem(35px) 0 0;
    margin: 0;
    .p-messages-close {
      position: absolute;
      top: calculateRem(-4px);
      right: 0;
      font-size: calculateRem(30px);
    }
    .p-messages-icon {
      display: none;
    }
  }
}

.p-fileupload-buttonbar {
  padding: 0;
  .p-fileupload-choose {
    padding: 0.5em;
    .p-button-icon-left {
      left: 1em;
    }
  }
}

.p-button-icon-only {
  @include btn-states($buttonBg, $textInverseColor, darken($buttonBg, 10%), $textInverseColor);
  height: auto;
  border: none;
  border-radius: 0 calculateRem(4px) calculateRem(4px) 0;
  position: relative;
}
.date-form-group {
  .p-button-icon-only  {
    height: auto;
  }
}
legend {
  font-size: 1.2rem;
}

.grid {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 5px;
}

p-inputswitch {
  line-height: 0;
}

.p-inputswitch {
  background: $greyColor;
  border-radius: 17px;
  width: 3em;
  height: 1.75em;
  .p-inputswitch-slider {
    background: white;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    top: 3px;
    left: 3px;
  }
  &.p-inputswitch-checked {
    background: $themeColor;
    .p-inputswitch-slider {
      left: 21px;
    }
  }
  &.p-disabled {
    opacity: 0.6;
  }
}

.p-dropdown {
  .p-dropdown-label {
    border: calculateRem(1px) $commonBorder solid;
    border-radius: calculateRem(4px);
    overflow: hidden;
    text-overflow: ellipsis;
    color: $textColor;
  }
}

.p-dropdown-panel {
  background: $commonBg;
  border: calculateRem(1px) $commonBorder solid;
  border-radius: calculateRem(4px);
  .p-dropdown-filter {
    line-height: 1.1em;
    border: calculateRem(1px) $commonBorder solid;
    border-radius: calculateRem(4px);
  }
}

.p-datepicker {
  background: $commonBg;
  width: 300px !important;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
  padding: 0.2em;
}
.p-datepicker-header {
  position: relative;
  padding: 0.5em 0;
}

.p-datepicker table {
width: 100%;
font-size: .9em;
border-collapse: collapse;
margin: 0 0 0.4em;
}
.p-datepicker th {
padding: 0.5em;
text-align: center;
font-weight: bold;
border: 0;
}

.p-datepicker td{
padding: 0.5em;
text-align: center;
border: 0;
}

.p-disabled {
  color: #444 !important;
}

a,
a:not([href]):not([tabindex]),
.link-style,
.p-datepicker td:not(.p-datepicker-other-month)>span {
  cursor: pointer;
  color: $buttonBg;
  text-decoration: none;
  transition: color 0.25s ease;
  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus {
    text-decoration: underline;
    color: darken($themeColor, 10%);
  }
  &.btn-icon {
    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      text-decoration: none;
    }
  }
}

// globals styles
html,
body,
app-root,
.main,
#login {
  height: 100%;
}

h1 {
  font-size: calculateRem(28px);
  margin: calculateRem(3px) 0 calculateRem(31px);
}

h2 {
  font-size: calculateRem(25px);
  margin: calculateRem(3px) 0 calculateRem(28px);
}

hr {
  border-color: $separatorBorder;
}

section {
  position: relative;
  overflow: auto;
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

label,
legend {
  .fa-question-circle {
    font-size: 0.9em;
    padding: calculateRem(4px);
    position: relative;
    top: calculateRem(-1px);
    &:before {
      position: relative;
      top: calculateRem(1px);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.menu-tooltip {
  margin-left: calc(#{$menuCollapsedWidth} - #{$menuWidth});
}

.nl2br {
  white-space: pre-wrap;
}

.neutral-link {
  color: $neutralLinkColor;
  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus {
    text-decoration: underline;
    color: $textColor;
  }
}

.alert {
  .fa-info-circle {
    margin-right: calculateRem(5px);
  }
  ul {
    margin: 0;
    padding: 0 0 0 calculateRem(20px);
  }
}

.title-button-container {
  margin-top: calculateRem(3px);
}

.trosace {
  position: relative;
  height: calculateRem(90px);
  min-width: calculateRem(145px);
  background: url("/assets/images/rosace.png") no-repeat center center;
  span {
    position: absolute;
  }
  .tnorth,
  .tsouth {
    width: 100%;
    left: 0;
    text-align: center;
  }
  .tnorth {
    top: 0;
  }
  .tsouth {
    bottom: 0;
  }
  .teast,
  .twest {
    top: 50%;
    margin-top: calculateRem(-11px);
  }
  .teast {
    left: 50%;
    margin-left: calculateRem(30px);
  }
  .twest {
    right: 50%;
    margin-right: calculateRem(30px);
  }
}

.main,
#login {
  padding: 0;
}

.page {
  position: relative;
  overflow: auto;
  background: url(/assets/images/background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  > ng-component {
    height: 100%;
    min-height: 0;
    position: relative;
  }
  .page-language-choice {
    padding: calculateRem(21px) calculateRem(35px) 0;
  }
  .page-citation-choice {
    padding: calculateRem(21px) calculateRem(35px) 0;
    .btn.badge {
      margin-right: calculateRem(5px);
    }
    // See btn:hover (_buttons.scss:23)
    .btn.badge.selected {
      color: #212529;
      text-decoration: none;
    }
    .citation-nav-bar {
      margin-bottom: 5px;
    }
    .citation {
      padding: calculateRem(8px);
      background-color: white;
      max-height: 150px;
      overflow-x: hidden;
      overflow-y: scroll;
      blockquote {
        margin-bottom: 0px;
        line-height: 1.2;
      }
    }
  }
  .options-buttons-row {
    padding: calculateRem(21px);
  }
  .publication-asked {
    padding-top: 0;
    padding-bottom: calculateRem(35px);
    padding-left: calculateRem(21px);
    padding-right: calculateRem(21px);
  }
  .page-header-and-content {
    #overflow-y: scroll;
    .page-header {
      padding-top: 0;
    }
    .page-content {
      overflow: unset;
      height: fit-content;
      .subcontent {
        max-height: unset;
        overflow: unset;
      }
    }
  }
  .page-header {
    padding: calculateRem(21px) calculateRem(35px) 0;
    .ml-3:first-child {
      margin-left: 0px !important;
    }
  }
  .page-content {
    padding: 0 calculateRem(35px) calculateRem(35px);
    height: 100%;
    overflow: auto;
  }
  // Public page
  > app-public-metadata-detail {
    height: 100%;
    min-height: 0;
  }
}

.btn-icon {
  border: 0 none;
  background: transparent;
  font-size: 1.1rem;
  padding: calculateRem(8px) calculateRem(5px);
  &:focus {
    box-shadow: none;
  }
  .fa {
    margin: 0;
  }
}

.subcontent {
  background: $dataTableBg;
  padding: calculateRem(18px) calculateRem(20px);
  box-shadow: $defaultShadow;
  max-height: 100%;
  overflow: auto;
  #publication-guide-image {
    max-width: min(100%,500px);
  }
}

.publication-asked {
  .col {
    padding: 0px;
    h2 {
      margin-bottom: 0.5rem;
    }
  }
}

.informations-page-content {
  overflow: unset !important;
}

.informations-subcontent {
  max-height: unset;
  overflow: unset;
  margin-bottom: calculateRem(35px);
  small {
    font-size: 85%;
    color: rgb(100,100,100);
  }
  .vertical-list {
    margin-bottom: 0px;
  }
}

.metadata-field {
  position: relative;
  .alert-danger {
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: calculateRem(3px) calculateRem(10px);
    box-shadow: 0 calculateRem(1px) calculateRem(4px) rgba(20, 20, 20, 0.4);
    p {
      margin-bottom: 0;
    }
  }
  .thematics-section .alert-danger, .keywords-section .alert-danger {
    position: unset;
  }
  .col {
    .alert-danger {
      width: calc(100% - 30px);
    }
  }
  .tab-like {
    border-bottom: calculateRem(3px) transparent solid;
    position: relative;
    top: calculateRem(1px);
    cursor: pointer;
    transition: border-bottom 0.3s ease;
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: $formControlBorder;
    }
    &.active {
      border-bottom-color: $themeColor;
    }
  }
  .file-tabs-container {
    border-bottom: calculateRem(1px) $formControlBorder solid;
  }
  .btn-close {
    padding: 0;
    line-height: 0;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    color: $textColor;
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
    span {
      font-size: 2rem;
      line-height: 0.6rem;
    }
  }
  .file-size-error {
    color: $errorColor;
  }
}

.list-counter {
  font-size: calculateRem(15px);
  margin: calculateRem(10px) 0;
  color: $neutralColor;
}

.filter-field-container {
  .form-control {
    padding: calculateRem(1px) calculateRem(15px);
    height: auto;
    width: calculateRem(150px);
  }
}

.data-table {
  position: relative;
  .data-title {
    font-size: calculateRem(18px);
  }
  .table {
    margin-bottom: calculateRem(3px);
    th,
    td {
      font-size: calculateRem(13px);
      vertical-align: middle;
    }
    thead {
      background: $dataTableHeaderBg;
      th {
        color: $dataTableHeaderColor;
        border: 0 none;
        text-transform: uppercase;
        white-space: nowrap;
        span {
          white-space: normal;
        }
        &.sortable {
          position: relative;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
          &.text-center > span {
            position: relative;
            left: calculateRem(5px);
          }
          &:after {
            content: " ";
            position: relative;
            width: 0;
            height: 0;
            border: calculateRem(5px) transparent solid;
            left: calculateRem(12px);
          }
          &.asc:after {
            top: calculateRem(12px);
            border-top-color: $dataTableHeaderColor;
          }
          &.desc:after {
            top: calculateRem(-14px);
            border-bottom-color: $dataTableHeaderColor;
          }
        }
      }
    }
    tbody {
      td {
        color: $dataTableTextColor;
        border-top: 0 none;
        border-bottom: calculateRem(1px) solid $dataTableBorder;
        &.btn-icon-container {
          padding: 0;
          .dropdown-toggle {
            &:after {
              display: none;
            }
          }
          .dropdown-menu {
            margin-top: calculateRem(-10px);
            margin-left: calculateRem(-5px);
          }
        }
        .btn-icon {
          @include btn-icon-states($dataTableIconColor, $dataTableIconHoverColor);
          font-size: calculateRem(12px);
          width: calculateRem(35px);
          line-height: calculateRem(27px);
        }
      }
    }
    .group-sub-item {
      margin-left: 10px;
    }
  }
  .actions {
    width: calculateRem(50px);
  }
}

#admin-groups {
  .column-title {
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 0.5rem;
    }
  }
}

.status-pending {
  color: goldenrod;
}
.status-accepted {
  color: green;
}
.status-refused {
  color: red;
}
.load-icon {
  display: inline-block;
  font-size: 10px;
  margin: 0px;
  text-indent: -9999em;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $buttonBg;
  background: -moz-linear-gradient(left, $buttonBg 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $buttonBg 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $buttonBg 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $buttonBg 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $buttonBg 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.load-icon:before {
  width: 50%;
  height: 50%;
  background: $buttonBg;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.load-icon:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.pagination {
  margin: calculateRem(10px) 0 0;
  .page-link {
    color: $textColor;
    border-color: $paginationBorder;
    outline: 0;
    font-size: calculateRem(13px);
    &:active,
    &:focus,
    &:active:hover,
    &:active:focus {
      box-shadow: none;
      text-decoration: none;
      color: $textColor;
    }
  }
  .active {
    .page-link {
      background-color: $dataTableHeaderBg;
      color: $textColor;
      border-color: $paginationBorder;
    }
  }
  .disabled {
    .page-link {
      color: $disabledPaginationColor;
      border-color: $paginationBorder;
    }
  }
}

.right-panel {
  position: fixed;
  top: $headerHeight;
  right: 0;
  background: $commonBg;
  box-shadow: 0 0 calculateRem(5px) $commonShadow;
  width: calculateRem(460px);
  height: calc(100% - #{$headerHeight});
  overflow: auto;
  padding: calculateRem(20px);
  z-index: 12;
  h2 {
    font-size: calculateRem(27px);
    margin: 0 0 calculateRem(30px);
    padding-right: calculateRem(50px);
  }
  .btn.fa-times {
    position: absolute;
    top: calculateRem(8px);
    right: calculateRem(20px);
    font-size: calculateRem(40px);
    @include btn-icon-states($iconColor, $textColor);
  }
  label {
    font-size: calculateRem(16px);
  }
}

.bbox-container {
  padding: calculateRem(10px) calculateRem(15px) calculateRem(15px);
  border: calculateRem(1px) $formControlBorder solid;
  border-radius: calculateRem(4px);
  label,
  .form-control {
    font-size: calculateRem(14px);
  }
  label {
    margin-bottom: calculateRem(5px);
  }
  .form-control {
    height: auto;
    padding-top: calculateRem(3px);
    padding-bottom: calculateRem(3px);
  }
  .little-form-group {
    margin-bottom: calculateRem(5px);
  }
}

.extents-list {
  padding-left: 15px;
}

.confirm-modal {
  .modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .close {
    box-shadow: none;
    outline: 0;
    padding: 0 calculateRem(12px);
    font-size: calculateRem(50px);
    margin-top: calculateRem(-10px);
  }
  .modal-title {
    font-size: calculateRem(22px);
  }
}

.results-table-modal {
  .modal-dialog {
    margin: 0;
    top: 2.5%;
    left: 2.5%;
    max-width: 95%;
    height: 95%;
    .modal-content {
      width: 100%;
      height: 100%;
      ng-component {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.fa-circle-notch {
  animation-name: spinner;
  -o-animation-name: spinner;
  -ms-animation-name: spinner;
  -webkit-animation-name: spinner;
  -moz-animation-name: spinner;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

.modal-backdrop {
  /* bug fix - no overlay */
  display: none;
}
